<template>
  <div class="vscode">
    <MonacoEditor v-if="isNewEditor" v-model:code="currentConfiguredWidgetString" ref="monacoEditor" />
    <Vue3JsonEditor v-else v-model="currentConfiguredWidgetString" :expandedOnStart="false"
      @json-change="onJsonChange" />
  </div>
</template>

<script>
// import { changeProps } from 'find-and';
import { Vue3JsonEditor } from 'vue3-json-editor';
import MonacoEditor from '../MonacoEditor.vue';
import { copyContent } from '@/utils/clipboard';
import { uuid } from 'vue-uuid';
import { insertObjectAfter } from 'find-and';
export default {
  name: 'ConstructorConfigMenu',
  props: {
    isNewEditor: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  components: {
    Vue3JsonEditor,
    MonacoEditor
  },
  computed: {
    currentConfiguredWidgetString: {
      get() {
        if (this.isNewEditor) {
          if (this.$store.getters.currentConfiguredWidget) {
            return JSON.stringify(this.$store.getters.currentConfiguredWidget, null, 4);
          } else {
            return "// Click on any component to configure it";
          }
        }
        return this.$store.getters.currentConfiguredWidget;
      },
      set(value) {
        if (this.isNewEditor) {
          if (this.isJsonString(value)) {
            const parsedValue = JSON.parse(value);
            this.update(parsedValue);
          }
        } else {
          this.update(value);
        }
      }
    },
    onPageComponents: {
      get() {
        return this.$store.getters.onPageComponents;
      },
      set(value) {
        this.$store.commit('updateOnPageComponents', value);
      },
    },
  },
  data() {
    return {
      isEditorFocused: false,
    };
  },
  methods: {
    handleMouseEnter() {
      this.isEditorFocused = true;
    },
    handleMouseLeave() {
      this.isEditorFocused = false;
    },
    handleKeydown(event) {
      if(this.isEditorFocused) return;
      if (event.ctrlKey || event.metaKey) {
        if (event.key === 'c') {
          this.handleCopy();
          event.preventDefault(); // Prevent default copy action
        } else if (event.key === 'v') {
          this.handlePaste();
          event.preventDefault(); // Prevent default paste action
        }
      }
    },
    handleCopy() {
      copyContent(this.currentConfiguredWidgetString)
    },
    handlePaste() {
      navigator.clipboard.readText().then((value) => {
        const current = JSON.parse(this.currentConfiguredWidgetString);
        const copied = JSON.parse(value);
        if (copied && copied.id) {
          copied.id = uuid.v4();
        }
        this.onPageComponents = insertObjectAfter(this.onPageComponents, { id: current.id }, copied);
      });
    },
    changeProps(components, idProps, newProps) {
      // Check if the input components array is valid
      if (!Array.isArray(components) || components.length === 0) {
        return components; // Return as is if not a valid array
      }

      return components.map(component => {
        // If the component matches the id, update it
        if (component.id === idProps.id) {
          // Merge current properties with new properties
          return { ...component, ...newProps };
        }

        // If the component has nestedComponents, recursively update them
        if (component.nestedComponents && Array.isArray(component.nestedComponents)) {
          return {
            ...component,
            nestedComponents: this.changeProps(component.nestedComponents, idProps, newProps) // Recursive call
          };
        }

        // Return the unchanged component
        return component;
      });
    },
    onJsonChange(newValue) {
      this.currentConfiguredWidgetString = newValue;
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    update(value) {
      try {
        let newComponentList = this.changeProps(
          this.onPageComponents,
          { id: value.id },
          value
        );
        this.$store.commit('updatedCurrentConfiguredWidget', value);
        this.$store.commit(
          'updateOnPageComponents',
          Object.values(newComponentList)
        );
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    if (this.isNewEditor) {
      // Get the Monaco editor container element
      const editorContainer = this.$refs.monacoEditor.$el;

      // Add event listeners for mouse enter and mouse leave
      editorContainer.addEventListener('mouseenter', this.handleMouseEnter);
      editorContainer.addEventListener('mouseleave', this.handleMouseLeave);
    }
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    if (this.isNewEditor) {
      // Get the Monaco editor container element
      const editorContainer = this.$refs.monacoEditor.$el;

      // Add event listeners for mouse enter and mouse leave
      editorContainer.removeEventListener('mouseenter', this.handleMouseEnter);
      editorContainer.removeEventListener('mouseleave', this.handleMouseLeave);
    }
    this.$store.commit('updatedCurrentConfiguredWidget', '');
  }
};
</script>

<style scoped>
.textarea {
  width: 100%;
  min-height: 40em;
}

.vscode {
  height: 100%;
}
</style>
