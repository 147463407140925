<template>
  <div>
    <div>
      <router-link class="nav-text" :to="{ name: 'ProjectList' }"
        ><img :src="require('@/assets/main_logo.png')" alt="Logo" class="logo"
      /></router-link>
    </div>
    <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
      <a-menu-item v-for="item in nav_list" :key="item.id">
        <router-link class="nav-text" :to="{ name: item.name }">{{
          item.title
        }}</router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    name: 'DocSideNav',
    setup() {
      const selectedKeys = ref([]);
      const nav_list = [
        // { id: '1', name: 'GettingStarted', title: 'Getting Started' },
        // { id: '2', name: 'Docs', title: 'Test Stand' },
        // { id: '3', name: 'DemoAPI', title: 'Demo Fetch API' },
        { id: '4', name: 'BeActionsDoc', title: 'Actions' },
        { id: '5', name: 'FeActionsDoc', title: 'Event Handlers' },
      ];

      const route = useRoute();

      const setSelectedKey = () => {
        const currentRouteName = route.name;
        const matchedNavItem = nav_list.find(
          (item) => item.name === currentRouteName
        );
        if (matchedNavItem) {
          selectedKeys.value = [matchedNavItem.id];
        }
      };

      watch(route, setSelectedKey, { immediate: true });

      return {
        selectedKeys,
        nav_list,
      };
    },
  };
</script>

<style scoped>
  .logo {
    width: 200px;
    height: 84px;
  }
</style>
