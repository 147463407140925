<template>
  <div>
    <hr class="hr" />
  </div>
</template>

<script>
export default {
  name: "LineWidget",
};
</script>
<style scoped>
.hr {
  margin: 10px;
}
</style>