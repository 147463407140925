<template>
  <div class="pl-5">
    <div class="dropzone-info" v-if="!onPageComponents.length">DROP ZONE</div>

    <draggable-component
      :list="list"
      group="component"
      tag="ul"
      item-key="name"
      class="draggable"
    >
      <template #item="{ element }">
        <div class="list-group-item">
          <FactoryComponent
            @click="click(element)"
            :key="element.id"
            :id="element.id"
            :memo="element.memo"
            :componentName="element.name"
            :componentDisplayName="element.display_name"
            :nestedComponents="element.nestedComponents"
            :data="element.data"
            :actions="element.actions"
            :styles="element.styles"
          ></FactoryComponent>
          <span class="remove" @click="remove(element.id)">X</span>
          <nested-constructor
            :if="element.nestedComponents"
            :list="element.nestedComponents"
          />
        </div>
      </template>
    </draggable-component>
  </div>
</template>

<script>
  import FactoryComponent from './FactoryComponent';
  import { removeObject } from 'find-and';

  const component = {
    name: 'NestedConstructor',
    components: {
      FactoryComponent,
    },
    props: {
      list: Array,
    },
    computed: {
      onPageComponents: {
        get() {
          return this.$store.getters.onPageComponents;
        },
        set(value) {
          this.$store.commit('updateOnPageComponents', value);
        },
      },
      currentConfiguredWidget: {
        get() {
          return this.$store.getters.currentConfiguredWidget;
        },
        set(value) {
          this.$store.commit('updatedCurrentConfiguredWidget', value);
        },
      },
    },
    methods: {
      click(evn) {
        this.currentConfiguredWidget = evn;
      },
      remove(event_id) {
        this.onPageComponents = removeObject(this.onPageComponents, {
          id: event_id,
        });
      },
    },
  };

  export default component;
</script>

<style scoped>
  .drag-zone {
    background-color: aqua;
  }

  .list-group-item {
    position: relative;
  }

  .remove {
    position: absolute;
    top: 0;
    right: 0;
    padding: 3px;
    background-color: red;
    cursor: pointer;
  }

  .dropzone-info {
    position: sticky;
    top: 50%;
    text-align: center;
  }
</style>
