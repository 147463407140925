<template>
  <div
    class="listen_events fff-bg"
    :style="data.style"
    v-analytics="data.actions"
  >
    <section>
      <img class="img w-100" :src="data.src" :alt="data.alt" />
    </section>

    <div class="item-wrapper">
      <section v-if="data.title">
        <span class="title">{{ data.title }}</span>
      </section>
      <section v-if="data.sub_title">
        <span class="sub_title">{{ data.sub_title }}</span>
      </section>
      <section v-if="data.price">
        <span>{{ data.price.uah }}</span>
        <span> грн</span>
        <span> • </span>
        <span class="usd">{{ data.price.usd }}</span>
        <span class="usd"> $</span>
      </section>
      <section v-if="data?.characteristics" class="column-count">
        <div
          v-for="characteristic in data.characteristics"
          :key="characteristic"
        >
          <label-1-widget :data="characteristic" />
        </div>
      </section>
      <section :if="data?.labels">
        <div v-for="label in data.labels" :key="label">
          <label-2-widget :data="label" />
        </div>
      </section>
      <section class="row">
        <img
          src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,15c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7 S11.9,15,8,15z' fill='%239b9b9b'%3E%3C/path%3E%3Cpath d='M8,5.5C8,5.2,7.8,5,7.5,5S7,5.2,7,5.5V9h4.5C11.8,9,12,8.8,12,8.5S11.8,8,11.5,8H8V5.5z' fill='%239b9b9b'%3E%3C/path%3E%3C/svg%3E"
          alt=""
        />
        <span class="pl-5 date">{{ data.date }}</span>
      </section>
    </div>
  </div>
</template>

<script>
import Label1Widget from "./Label1Widget.vue";
export default {
  components: { Label1Widget },
  name: "ItemCard1",
  props: {
    data: {
      required: true,
      type: Object,
    },
    actions: {
      required: false,
      type: Object,
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
}
.title {
  font-weight: 400;
}
.item-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}
.column-count {
  column-count: 2;
}
.date {
  font-size: 14px;
  color: #9b9b9b;
}
.usd {
  color: #3c9806;
  font-size: 22;
  font-weight: 700;
}
section {
  padding-bottom: 5px;
}
</style>