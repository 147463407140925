<template>
  <div>WebViewWidget</div>
</template>

<script>
export default {
  name: "WebViewWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    name: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      widgets: [],
    };
  },
  methods: {},

};
</script>