<template>
    <div class="w-100">
        <photo-provider>
            <photo-consumer v-for="src in data.images" :intro="src" :key="src" :src="src">
                <img :src="src" height="400" class="view-box w-100">
            </photo-consumer>
        </photo-provider>
    </div>
</template>

<script>
import { PhotoProvider, PhotoConsumer } from 'vue3-photo-preview';
export default {
    name: "GaleryWidget",
    components: { PhotoProvider, PhotoConsumer },
    props: {
        data: {
            required: true,
            type: Object,
        }
    }
};
</script>
<style scoped>
@import 'vue3-photo-preview/dist/index.css';
</style>