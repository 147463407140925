<template>
  <label v-if="label">{{ label }}</label>
  <input
    v-bind="$attrs"
    type="checkbox"
    :checked="this.modelValue"
    v-model="checked"
    @change="$emit('update:modelValue', checked)"
    class="field"
  />
</template>

<script>
  export default {
    name: 'BaseCheckbox',
    data() {
      return {
        checked: this.modelValue,
      };
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      modelValue: {
        type: [Boolean, String],
        default: false,
      },
    },
  };
</script>
