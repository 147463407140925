<template>
  <div :if="data" id="header" class="listen_events widget row space-between header" :style="data.style">
    <section class="row p-5">
      <img v-if="data.images[0].src" width="30" height="30" :src="data.images[0].src" :alt="data.images[0].alt"
        v-analytics="data.images[0].actions" />
    </section>
    <section class="row center">
      <span>{{ data.title }}</span>
    </section>
    <section class="row min20 max30 space-between">
      <div>
        <img v-if="data.images[1].src" width="30" height="30" :src="data.images[1].src" :alt="data.images[1].alt"
          v-analytics="data.images[1].actions" />
      </div>
      <div>
        <img v-if="data.images[2].src" width="30" height="30" :src="data.images[2].src" :alt="data.images[2].alt"
          v-analytics="data.images[2].actions" />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HeaderWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
  methods: {
  },
};
</script>
<style scoped>
.header {
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  min-height: 45px;
  min-width: 200px;
  z-index: 999;
}
</style>