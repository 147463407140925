<template>
  <input
    v-bind="$attrs"
    type="checkbox"
    :checked="this.modelValue"
    v-model="checked"
    @change="$emit('update:modelValue', checked)"
    id="switch"
  /><label for="switch"></label>
</template>

<script>
  export default {
    name: 'BaseRadio',
    data() {
      return {
        checked: this.modelValue,
      };
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      modelValue: {
        type: [Boolean, String],
        default: false,
      },
    },
  };
</script>
<style scoped>
  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 70px;
    height: 35px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #bada55;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 40px;
  }
</style>
