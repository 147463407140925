import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const base_path = '/api/admin/v1/screens';
const update_and_broadcast_path = '/api/admin/v1/screens/update-and-broadcast';

const screenAPI = {
  async getAllScreens(project_id) {
    return api
      .get(`/api/admin/v1/screens_list/${project_id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async getScreen(screen_id) {
    return api
      .get(`${base_path}/${screen_id}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async createScreen(project_id, data) {
    return api
      .post(base_path, { project_id: project_id, screen: data })
      .then((response) => {
        $toast.success('Screen Created');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateScreen(screen_id, data) {
    return api
      .put(`${base_path}/${screen_id}`, { screen: data.body })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Screen Updated');
        }
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateAndBroadcastScreen(screen_id, data) {
    return api
      .put(`${update_and_broadcast_path}/${screen_id}`, { screen: data.body })
      .then((response) => {
        if (response.data.nestedComponents) {
          $toast.success('Screen Updated');
        }
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async deleteScreen(screen_id) {
    return api
      .delete(`${base_path}/${screen_id}`)
      .then((response) => {
        $toast.success('Screen Deleted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default screenAPI;
