<template>
  <div>
    <div class="row">
      <a-input
        name="name"
        type="text"
        v-model:value="newWidgetName"
        placeholder="New Widget"
      />
      <a-button @click="saveNewWidget()" type="primary">Save</a-button>
    </div>
    <a-table
      :data-source="widgetList"
      :columns="columns"
      :pagination="{ pageSize: 20 }"
      :scroll="{ y: 600 }"
    >
      <template
        #customFilterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="handleSearch(confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(confirm)"
          >
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'id'">
          <router-link
            v-if="record.id"
            :to="{ name: 'CustomWidget', params: { widget_id: record.id } }"
          >
            {{ record.id }}
          </router-link>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
  import { mapActions, mapMutations } from 'vuex';
  import { SearchOutlined } from '@ant-design/icons-vue';
  import 'vue3-carousel/dist/carousel.css';

  export default {
    name: 'WormholeListPage',
    components: {
      SearchOutlined,
    },
    data() {
      return {
        newWidgetName: '',
        userId: this.$store.getters.getUserID,
        columns: [
          {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            customFilterDropdown: true,
            onFilter: (value, record) =>
              record.id.toString().toLowerCase().includes(value.toLowerCase()),
          },
          {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
          },
        ],
      };
    },
    methods: {
      ...mapActions(['getAllCustomWidgets', 'createCustomWidget']),
      ...mapMutations(['updatedCustomWidgetList']),
      handleSearch(confirm) {
        confirm();
      },
      handleReset(clearFilters) {
        clearFilters({ confirm: true });
      },
      saveNewWidget() {
        const data = {
          user_id: this.userId,
          name: this.newWidgetName,
          content: null,
        };
        this.createCustomWidget(data);
      },
    },
    mounted() {
      this.updatedCustomWidgetList();
      this.getAllCustomWidgets(this.userId);
    },
    computed: {
      widgetList: {
        get() {
          return this.$store.getters.customWidgetList;
        },
      },
    },
  };
</script>

<style scoped>
  .carousel__item {
    min-height: 500px;
    width: 250px;
    background-color: green;
    color: orange;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__item__new {
    min-height: 500px;
    width: 250px;
    background-color: blueviolet;
    color: orange;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__slide {
    padding: 10px;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
  }

  .carusel {
    margin: auto;
    max-width: 70%;
  }

  /* ================================================================= */
  .screen-list {
    max-width: 1200px;
    margin: auto;
  }

  .w-15 {
    width: 15%;
  }

  .w-40 {
    width: 40%;
  }

  .w-45 {
    width: 45%;
  }

  .border {
    border: 1px solid black;
  }

  .row {
    padding: 8px;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell .editable-cell-input-wrapper,
  .editable-cell .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell .editable-cell-icon,
  .editable-cell .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell .editable-cell-icon:hover,
  .editable-cell .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }
</style>
