<template>
  <div class="container">
    <Markdown :source="source" :html="true" />
  </div>
</template>

<script setup>
import Markdown from 'vue3-markdown-it';
import { ref } from 'vue';

const source = ref(`
# API Documentation

  * [SdufWeb.Admin.DemoController](#sdufweb-admin-democontroller)
    * [marketplace](#sdufweb-admin-democontroller-marketplace)
    * [airbnb](#sdufweb-admin-democontroller-airbnb)
    * [chat_preview](#sdufweb-admin-democontroller-chat_preview)
    * [tracking_map_position](#sdufweb-admin-democontroller-tracking_map_position)
    * [marker_click](#sdufweb-admin-democontroller-marker_click)
    * [tinder](#sdufweb-admin-democontroller-tinder)
    * [tinder_load_more](#sdufweb-admin-democontroller-tinder_load_more)
    * [login](#sdufweb-admin-democontroller-login)
    * [change_btn_color](#sdufweb-admin-democontroller-change_btn_color)
    * [send_push_notification](#sdufweb-admin-democontroller-send_push_notification)
    * [open_float_card](#sdufweb-admin-democontroller-open_float_card)
    * [open_popup](#sdufweb-admin-democontroller-open_popup)
    * [insert_before](#sdufweb-admin-democontroller-insert_before)
    * [insert_after](#sdufweb-admin-democontroller-insert_after)
    * [remove_button](#sdufweb-admin-democontroller-remove_button)
    * [new_chat_message](#sdufweb-admin-democontroller-new_chat_message)
    * [typing](#sdufweb-admin-democontroller-typing)


## <a id=sdufweb-admin-democontroller></a>SdufWeb.Admin.DemoController
### <a id=sdufweb-admin-democontroller-marketplace></a>marketplace
#### GET /api/demo/marketplace returns marketplace cards and paginator

##### Request
* __Method:__ POST
* __Path:__ /api/demo/marketplace

##### Response
* __Status__: 200
* __Response headers:__
\`\`\`
content - type: application / json; charset = utf - 8
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDDYCW3EVSnUAAApD
  \`\`\`
* __Response body:__
\`\`\`json
[
  {
    "name": "ItemCard2",
    "id": "cfa286db-5c69-4c40-991c-68c661af1e94",
    "data": {
      "title": "Pitt Meadows",
      "sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "src": "https://adventures.com/media/207686/s-toronto-canada-city-skyline-skyscrapers-buildings-seaside-sunny.jpg",
      "price": {
        "USD": 250,
        "CAD": 300
      },
      "params": [],
      "labels": [
        {
          "text": "Lake",
          "id": 1
        },
        {
          "text": "Fun",
          "id": 2
        },
        {
          "text": "Road Trip",
          "id": 2
        },
        {
          "text": "3h from Tronto",
          "id": 2
        },
        {
          "text": "3* hotel",
          "id": 2
        }
      ],
      "alt": "",
      "actions": {
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ItemCard2",
    "id": "e0d33d0d-eeab-439f-9ebe-ea0692774eaa",
    "data": {
      "title": "Pitt Meadows",
      "sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "src": "https://lp-cms-production.imgix.net/2023-11/GettyImages-554291755-16.9.jpg?auto=format&w=1440&h=810&fit=crop&q=75",
      "price": {
        "USD": 250,
        "CAD": 300
      },
      "params": [],
      "labels": [
        {
          "text": "Lake",
          "id": 1
        },
        {
          "text": "Fun",
          "id": 2
        },
        {
          "text": "Road Trip",
          "id": 2
        },
        {
          "text": "3h from Tronto",
          "id": 2
        },
        {
          "text": "3* hotel",
          "id": 2
        }
      ],
      "alt": "",
      "actions": {
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ItemCard2",
    "id": "244913cd-3576-46af-a264-e32dc56e7bf9",
    "data": {
      "title": "Port Coquitlam",
      "sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "src": "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/74000/74761-Alberta.jpg",
      "price": {
        "USD": 250,
        "CAD": 300
      },
      "params": [],
      "labels": [
        {
          "text": "Lake",
          "id": 1
        },
        {
          "text": "Fun",
          "id": 2
        },
        {
          "text": "Road Trip",
          "id": 2
        },
        {
          "text": "3h from Tronto",
          "id": 2
        },
        {
          "text": "3* hotel",
          "id": 2
        }
      ],
      "alt": "",
      "actions": {
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ItemCard2",
    "id": "68a618c4-2223-448a-bc89-5cf8bedf2efe",
    "data": {
      "title": "Coquitlam",
      "sub_title": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      "src": "https://adventures.com/media/207686/s-toronto-canada-city-skyline-skyscrapers-buildings-seaside-sunny.jpg",
      "price": {
        "USD": 250,
        "CAD": 300
      },
      "params": [],
      "labels": [
        {
          "text": "Lake",
          "id": 1
        },
        {
          "text": "Fun",
          "id": 2
        },
        {
          "text": "Road Trip",
          "id": 2
        },
        {
          "text": "3h from Tronto",
          "id": 2
        },
        {
          "text": "3* hotel",
          "id": 2
        }
      ],
      "alt": "",
      "actions": {
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "PaginationWidget",
    "id": "63b11a4f-46ab-4f95-ba21-de4b5ddddf35",
    "data": {
      "callbackUrl": "http://localhost:4002/api/demo/marketplace"
    }
  }
]
  \`\`\`

### <a id=sdufweb-admin-democontroller-airbnb></a>airbnb
#### GET /api/demo/airbnb returns map component

##### Request
* __Method:__ POST
* __Path:__ /api/demo/airbnb

##### Response
* __Status__: 200
* __Response headers:__
\`\`\`
content - type: application / json; charset = utf - 8
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEhkw3Mg084AAAcI
  \`\`\`
* __Response body:__
\`\`\`json
[
  {
    "name": "MaplibreWidget",
    "id": "6e4c65ad-0e3e-4ddd-a0b3-b8ba525af8c5",
    "data": {
      "style": {
        "width": "100%",
        "height": "300px"
      },
      "markers": [
        {
          "type": "text",
          "text": "text",
          "position": {
            "lng": 6.84212,
            "lat": 51.093048
          },
          "name": "id=1",
          "click": {
            "url": "http://localhost:4002/api/demo/marker_click",
            "type": "async_post"
          }
        },
        {
          "type": "text",
          "text": "text",
          "position": {
            "lng": 6.24212,
            "lat": 51.083048
          },
          "name": "id=2",
          "click": {
            "url": "http://localhost:4002/api/demo/marker_click",
            "type": "async_post"
          }
        },
        {
          "type": "",
          "text": "text",
          "position": {
            "lng": 7.24212,
            "lat": 52.083048
          },
          "name": "id=8",
          "click": {
            "url": "http://localhost:4002/api/demo/marker_click",
            "type": "async_post"
          }
        }
      ],
      "center": {
        "lng": 6.84212,
        "lat": 51.093048
      },
      "actions": {
        "track_position": {
          "url": "http://localhost:4002/api/demo/tracking_map_position",
          "type": "async_post"
        }
      }
    }
  }
]
  \`\`\`

### <a id=sdufweb-admin-democontroller-chat_preview></a>chat_preview
#### GET /api/demo/chat_preview returns chat preview cards with paginator

##### Request
* __Method:__ POST
* __Path:__ /api/demo/chat_preview

##### Response
* __Status__: 200
* __Response headers:__
\`\`\`
content - type: application / json; charset = utf - 8
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEgk73F98woAAAjk
  \`\`\`
* __Response body:__
\`\`\`json
[
  {
    "name": "ChatPreviewWidget",
    "id": "8a7b2e6c-768c-467e-b864-d2d84c990e73",
    "data": {
      "title": "Langley",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://mediaim.expedia.com/destination/1/4e5bd60a120a239826ea0b15c6df71b5.jpg",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "a0198698-9de3-4cab-831d-736de4bb6ef9",
    "data": {
      "title": "Toronto",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMQyWw8EwSzulQ_IqfbLtMlMEfuRxgvEEehw&usqp=CAU",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "e4266de5-478e-4fa0-ba40-0bcedff3b4ff",
    "data": {
      "title": "Langley",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://adventures.com/media/207686/s-toronto-canada-city-skyline-skyscrapers-buildings-seaside-sunny.jpg",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "4ee8ec56-69d3-4aff-9dcb-fa1d86eed176",
    "data": {
      "title": "North Vancouver",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://www.travelandleisure.com/thmb/6LKBcNi2iFSiBdTpp_4xTqHenfg=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-banff-national-park-BEAUTYCANADA0623-fc3b0496f842412f9a8299f8ffd18499.jpg",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "319467b9-fcb9-4cd7-813d-b68bf7cfd76d",
    "data": {
      "title": "New Westminster",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNiTiBPwBPE6zJk5nFcJu-g_zhpoEHrbYO76VCUQms-anxJPc8v-e3_Db5CnT3BrCgxFk&usqp=CAU",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "f4d1bdce-1230-4143-9b24-8b94a7f5b9ba",
    "data": {
      "title": "Pitt Meadows",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLdmpT7U3sOMl73XSj48fnl-JfbT9BdrzHHmjfCur4SggKd8V5w4CvOkigX9mvpJtt-PE&usqp=CAU",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "80e2df86-9a87-417b-995d-660486e1592d",
    "data": {
      "title": "North Vancouver",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://lp-cms-production.imgix.net/2023-11/GettyImages-554291755-16.9.jpg?auto=format&w=1440&h=810&fit=crop&q=75",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "fd879f92-d232-4096-b977-c1659c2e2096",
    "data": {
      "title": "New Westminster",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://adventures.com/media/207686/s-toronto-canada-city-skyline-skyscrapers-buildings-seaside-sunny.jpg",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "01b0faa1-b898-48c0-9211-1cda1da79c34",
    "data": {
      "title": "Delta",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNiTiBPwBPE6zJk5nFcJu-g_zhpoEHrbYO76VCUQms-anxJPc8v-e3_Db5CnT3BrCgxFk&usqp=CAU",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "ChatPreviewWidget",
    "id": "84b43836-e16d-4ed2-b1cf-262bb0c0c462",
    "data": {
      "title": "Port Coquitlam",
      "text": "Lorem ipsum dolor sit amet, consectetur",
      "src": "https://adventures.com/media/207686/s-toronto-canada-city-skyline-skyscrapers-buildings-seaside-sunny.jpg",
      "date": "2021.01.01",
      "actions": {
        "scroll": {
          "url": "url",
          "type": "async_post",
          "params": {
            "parameter": "parameter"
          }
        },
        "dblclick": {
          "url": "url",
          "type": "async_get"
        },
        "click": {
          "type": "route_to_local",
          "screen_name": "home"
        }
      }
    }
  },
  {
    "name": "PaginationWidget",
    "id": "2182b90a-9ce9-4b9f-82e2-23d0094597d3",
    "data": {
      "callbackUrl": "http://localhost:4002/api/demo/chat_preview"
    }
  }
]
  \`\`\`

### <a id=sdufweb-admin-democontroller-tracking_map_position></a>tracking_map_position
#### POST /api/demo/tracking_map_position processes tracking map position

##### Request
* __Method:__ POST
* __Path:__ /api/demo/tracking_map_position
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "project_id": "project_123",
      "payload": {
    "params": {
      "region": "some_region"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEa9J0eQtfUAAAqj
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-marker_click></a>marker_click
#### POST /api/demo/marker_click processes marker click event

##### Request
* __Method:__ POST
* __Path:__ /api/demo/marker_click
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "project_id": "project_123"
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEgVpD7MDW4AAAui
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-tinder></a>tinder
#### GET /api/demo/tinder returns tinder component

##### Request
* __Method:__ POST
* __Path:__ /api/demo/tinder

##### Response
* __Status__: 200
* __Response headers:__
\`\`\`
content - type: application / json; charset = utf - 8
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEfmLQl5Gf4AAAjE
  \`\`\`
* __Response body:__
\`\`\`json
[
  {
    "name": "TinderWidget",
    "id": "01afc959-638b-4bd2-81c5-4c00f7e1aa72",
    "data": {
      "cards": [
        {
          "title": "Langley",
          "src": "https://www.travelandleisure.com/thmb/6LKBcNi2iFSiBdTpp_4xTqHenfg=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-banff-national-park-BEAUTYCANADA0623-fc3b0496f842412f9a8299f8ffd18499.jpg",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          "title": "Port Coquitlam",
          "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQMQyWw8EwSzulQ_IqfbLtMlMEfuRxgvEEehw&usqp=CAU",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          "title": "Pitt Meadows",
          "src": "https://www.travelandleisure.com/thmb/6LKBcNi2iFSiBdTpp_4xTqHenfg=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-banff-national-park-BEAUTYCANADA0623-fc3b0496f842412f9a8299f8ffd18499.jpg",
          "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        }
      ],
      "actions": {
        "load_more": {
          "url": "http://localhost:4002/api/demo/tinder_load_more",
          "type": "sync_post"
        }
      }
    }
  }
]
  \`\`\`

### <a id=sdufweb-admin-democontroller-tinder_load_more></a>tinder_load_more
#### POST /api/demo/tinder_load_more returns more tinder cards

##### Request
* __Method:__ POST
* __Path:__ /api/demo/tinder_load_more

##### Response
* __Status__: 200
* __Response headers:__
\`\`\`
content - type: application / json; charset = utf - 8
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEgKV_1X1sYAAAuC
  \`\`\`
* __Response body:__
\`\`\`json
[
  {
    "title": "1Pitt Meadows",
    "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLdmpT7U3sOMl73XSj48fnl-JfbT9BdrzHHmjfCur4SggKd8V5w4CvOkigX9mvpJtt-PE&usqp=CAU",
    "description": "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
  {
    "title": "2Pitt Meadows",
    "src": "https://mediaim.expedia.com/destination/1/4e5bd60a120a239826ea0b15c6df71b5.jpg",
    "description": "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  },
  {
    "title": "3Langley",
    "src": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLdmpT7U3sOMl73XSj48fnl-JfbT9BdrzHHmjfCur4SggKd8V5w4CvOkigX9mvpJtt-PE&usqp=CAU",
    "description": "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry."
  }
]
  \`\`\`

### <a id=sdufweb-admin-democontroller-login></a>login
#### POST /api/demo/login processes login action

##### Request
* __Method:__ POST
* __Path:__ /api/demo/login
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "data": {
      "password": "pass",
        "name": "user"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEhYVIIMiMYAAAlE
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-change_btn_color></a>change_btn_color
#### POST /api/demo/change_btn_color processes button color change

##### Request
* __Method:__ POST
* __Path:__ /api/demo/change_btn_color
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "params": {
      "id": "parent_id"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEd_wp0PvUkAAAik
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-send_push_notification></a>send_push_notification
#### POST /api/demo/send_push_notification sends push notification

##### Request
* __Method:__ POST
* __Path:__ /api/demo/send_push_notification
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123"
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEpqGMnxDYQAAAvC
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-open_float_card></a>open_float_card
#### POST /api/demo/open_float_card opens a float card

##### Request
* __Method:__ POST
* __Path:__ /api/demo/open_float_card
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "project_id": "project_123"
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEg63oQTRZEAAAkE
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-open_popup></a>open_popup
#### POST /api/demo/open_popup opens a popup

##### Request
* __Method:__ POST
* __Path:__ /api/demo/open_popup
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "project_id": "project_123"
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEff9T1wOtEAAArj
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-insert_before></a>insert_before
#### POST /api/demo/insert_before inserts widget before another

##### Request
* __Method:__ POST
* __Path:__ /api/demo/insert_before
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "params": {
      "id": "parent_id"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEfxgepIQwEAAAsD
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-insert_after></a>insert_after
#### POST /api/demo/insert_after inserts widget after another

##### Request
* __Method:__ POST
* __Path:__ /api/demo/insert_after
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "params": {
      "id": "parent_id"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEfZaHtjyaYAAArD
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-remove_button></a>remove_button
#### POST /api/demo/remove_button removes a button

##### Request
* __Method:__ POST
* __Path:__ /api/demo/remove_button
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "params": {
      "id": "parent_id"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEhDrY9ssGsAAAkk
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-new_chat_message></a>new_chat_message
#### POST /api/demo/new_chat_message processes a new chat message

##### Request
* __Method:__ POST
* __Path:__ /api/demo/new_chat_message
* __Request headers:__
\`\`\`
content - type: multipart / mixed; boundary = plug_conn_test
  \`\`\`
* __Request body:__
\`\`\`json
{
  "user_id": "user_123",
    "screen_id": "screen_123",
      "project_id": "project_123",
        "payload": {
    "params": {
      "value": "Hello!"
    }
  }
}
\`\`\`

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEf - pYbbOe8AAAsj
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`

### <a id=sdufweb-admin-democontroller-typing></a>typing
#### POST /api/demo/typing handles typing action

##### Request
* __Method:__ POST
* __Path:__ /api/demo/typing

##### Response
* __Status__: 204
* __Response headers:__
\`\`\`
cache - control: max - age=0, private, must - revalidate
x - request - id: F - ouDEgE5 - YzugIAAAti
  \`\`\`
* __Response body:__
\`\`\`json

  \`\`\`



`);
</script>

<style scoped>
.container {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

:deep(code) {
  background-color: #f5f5f5;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
}

:deep(pre) {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}
</style>
