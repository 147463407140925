<template>
  <input
    :type="data.type"
    :style="data.style"
    :src="data.src"
    :border="data.border"
    :name="data.name"
    :placeholder="data.placeholder"
    :disabled="data.disabled"
    :required="data.required"
    :value="data.value"
    class="input"
  />
</template>

<script>
export default {
  name: "InputWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      text: "",
    };
  },
};
</script>
<style scoped >
.input {
  padding:10px;
  border-radius:10px;
  width: calc(100% - 25px);
}
</style>