<template>
    <div>
        <a-modal
            v-model:visible="isModalVisible"
            title="What's New"
            @ok="handleOk"
            @cancel="handleCancel"
            class="whats-new-modal"
        >
            <div class="modal-content">
                <p class="modal-header">
                    Latest Update: <strong>{{ latestUpdate.version }}</strong> ({{ latestUpdate.date }})
                </p>
                <p class="modal-subheader">Changes:</p>
                <ul class="change-list">
                    <li v-for="change in latestUpdate.changes" :key="change" class="change-item">{{ change }}</li>
                </ul>
                <div class="modal-footer">
                    <a-button type="link" @click="goToHistory">View Full Update History</a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const isModalVisible = ref(false);
        const updates = ref([
            {
                version: '0.7.10',
                date: '2024-11-01',
                changes: ['Initial release with basic features.'],
            },
            {
                version: '0.7.11',
                date: '2024-11-03',
                changes: [
                    'Introduced keyboard shortcuts: Ctrl + C and Ctrl + V for quick component copy/paste.',
                    'Added a search feature in the documentation for easier navigation.'
                ],
            },
        ]);
        const latestUpdate = ref(updates.value[updates.value.length - 1]);

        // Check localStorage to see if the user has seen the updates
        const hasSeenWhatsNew = localStorage.getItem('hasSeenWhatsNew');
        const hasSeenTour = localStorage.getItem('tourCompleted-project-list-page');
        const currentVersion = 1;

        // Show the modal only if the user hasn't seen it yet
        if (hasSeenTour && (!hasSeenWhatsNew || hasSeenWhatsNew < currentVersion)) {
            isModalVisible.value = true;
            localStorage.setItem('hasSeenWhatsNew', currentVersion);
        }

        function showModal() {
            isModalVisible.value = true;
        }

        function handleOk() {
            isModalVisible.value = false;
        }

        function handleCancel() {
            isModalVisible.value = false;
        }

        function goToHistory() {
            // Navigate to the update history page
            this.$router.push('/update-history');
        }

        return {
            isModalVisible,
            latestUpdate,
            showModal,
            handleOk,
            handleCancel,
            goToHistory,
        };
    },
};
</script>

<style scoped>
.whats-new-modal {
    max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.modal-content {
    padding: 20px;
}

.modal-header {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
}

.modal-subheader {
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 10px;
    color: #555;
}

.change-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
}

.change-item {
    margin-bottom: 8px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}
</style>
