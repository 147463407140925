<template>
  <div class="page-container">
    <div class="components-wrap">
      <div class="component-item" v-for="(element, index) in allComponents" :key="element.id">
        <div class="component-title">
          <h3>{{ element.name }}</h3>
        </div>

        <div class="component-content">
          <div v-if="editModeIndexes.includes(index)" class="widget">
            <component :key="editModeWidgets[index].id" :is="editModeWidgets[index].name"
              :nestedComponents="editModeWidgets[index].nestedComponents" :data="editModeWidgets[index].data"
              :constructor="false" class="block">
            </component>
          </div>
          <div v-else class="widget">
            <component :key="element.id" :is="element.name" :nestedComponents="element.nestedComponents"
              :data="element.data" :constructor="false" class="widget-block"></component>
          </div>

          <div v-if="showCode[index]" class="component-code">
            <pre>{{ JSON.stringify(element, null, 2) }}</pre>
          </div>
        </div>

        <div class="component-actions">
          <a-button @click="editMode(index, element)">
            <template #icon>
              <EditOutlined />
            </template>
          </a-button>
          <a-button @click="copyContent(JSON.stringify(element))">
            <template #icon>
              <CopyOutlined />
            </template>
          </a-button>
        </div>
        <div v-if="editModeIndexes.includes(index)" class="component-edit">
          <Vue3JsonEditor class="json-editor" v-model="editModeWidgets[index]" :expandedOnStart="true"
            @json-change="onJsonChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { changeProps } from 'find-and';
import { PlusOutlined, EditOutlined, CopyOutlined, CodeOutlined } from '@ant-design/icons-vue';
import { Vue3JsonEditor } from 'vue3-json-editor';
import { getCurrentInstance } from 'vue';
import { copyContent } from '@/utils/clipboard';


export default {
  name: 'ExamplesPage',
  components: {
    PlusOutlined,
    EditOutlined,
    CopyOutlined,
    CodeOutlined,
    Vue3JsonEditor,
  },
  data() {
    return {
      selectedWidget: null,
      editModeIndexes: [],
      editModeWidgets: [],
      showCode: [],
    };
  },
  methods: {
    editMode(index, element) {
      const indexInArray = this.editModeIndexes.indexOf(index);
      const elementInArray = this.editModeIndexes.indexOf(index);
      if (indexInArray === -1) {
        this.editModeIndexes.push(index);
        this.editModeWidgets[index] = element;
      } else {
        this.editModeIndexes.splice(indexInArray, 1);
        this.editModeWidgets.splice(elementInArray, 1);
      }
    },
    onJsonChange(data) {
      this.editModeWidgets = changeProps(
        this.editModeWidgets,
        { id: data.id },
        { ...data }
      );
    },
    isComponentAvailable(componentName) {
      const instance = getCurrentInstance();
      return !!instance?.appContext.components[componentName];
    },
    toggleCode(index) {
      this.$set(this.showCode, index, !this.showCode[index]);
    },
  },
  computed: {
    allComponents() {
      console.log(this.$store.getters.allComponents)
      return this.$store.getters.allComponents.filter(el => this.isComponentAvailable(el.name));
    },
  },
};
</script>

<style scoped>
.page-container {
  padding: 20px;
}

.page-header {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
}

.header-button {
  margin-left: 10px;
}

.components-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.component-edit {
  height: 300px;
  overflow: scroll;
}

.component-item {
  flex: 1 1 calc(50% - 20px);
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  max-width: calc(50% - 20px);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.component-content {
  flex: 1;
}

.component-code {
  overflow: auto;
  max-width: 100%;
  max-height: 200px;
  background: #fafafa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  white-space: pre-wrap;
}

.json-editor {
  width: 100%;
  max-width: 100%;
  height: 200px;
  margin-bottom: 10px;
}

.widget {
  width: 100%;
  max-width: 100%;
}

.widget-block {
  margin-top: 10px;
}

.component-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.action-btn {
  margin-top: 10px;
}

.edit-btn {
  background-color: #4caf50;
  color: #fff;
}

.copy-btn {
  background-color: #2196f3;
  color: #fff;
}

.toggle-code-btn {
  background-color: #f5a623;
  color: #fff;
}

@media (max-width: 1200px) {
  .component-item {
    flex: 1 1 calc(100% - 20px);
  }
}

@media (max-width: 768px) {
  .component-code {
    font-size: 12px;
  }

  .json-editor {
    height: 150px;
  }
}
</style>
