<template>
    <div>
        <vue-lightbox-advanced @clicked:index="handleShow" :items="data.images" :cells="data.cells" />

        <photo-slider :items="items" :visible="visible" :index="index" @changeIndex="changeIndex"
            @clickMask="handleHide" @closeModal="handleHide" />
    </div>
</template>

<script>
import VueLightboxAdvanced from 'vue-lightbox-advanced'
import { PhotoSlider } from 'vue3-photo-preview';
export default {
    name: "LigthBoxWidgetBackup",
    components: { VueLightboxAdvanced, PhotoSlider },
    props: {
        data: {
            required: true,
            type: Object,
        }
    },
    data() {
        return {
            index: 0,
            visible: false,
            propsItems: this.data.images,
        };
    },
    computed: {
        items: {
            get() {
                return this.propsItems.map(src => ({
                    src,
                    key: src,
                    intro: src
                }));
            }
        }
    },
    methods: {
        changeIndex(index) {
            this.index = index;
        },

        handleShow(index) {
            if (index > -1) {
                this.index = index;
                this.visible = true;
            }
        },

        handleHide() {
            this.visible = false;
        }
    }
};
</script>
<style scoped>
@import 'vue3-photo-preview/dist/index.css';
@import 'vue-lightbox-advanced/dist/vue-lightbox-advanced.css';
</style>