export function time2TimeAgo(ts) {
    // This function computes the delta between the
    // provided timestamp and the current time, then test
    // the delta for predefined ranges.

    var d = new Date(); // Gets the current time
    var nowTs = d.getTime();
    var milliseconds = nowTs - ts;

    // More than two days
    if (milliseconds > 2 * 24 * 3600 * 1000) {
        return 'a few days ago';
    }
    // A day
    if (milliseconds > 24 * 3600 * 1000) {
        return 'yesterday';
    }

    if (milliseconds > 3600 * 1000) {
        return 'a few hours ago';
    }
    if (milliseconds > 1800 * 1000) {
        return 'Half an hour ago';
    }
    if (milliseconds > 60 * 1000) {
        return Math.floor(milliseconds / (60 * 1000)) + ' minutes ago';
    }

    return milliseconds / 1000 + ' seconds ago';
}