<template>
  <div>
    <api-doc v-for="api in apis" :key="api.name" :api="api" />
  </div>
</template>

<script>
import ApiDoc from './ApiDoc.vue';

export default {
  components: {
    ApiDoc,
  },
  data() {
    return {
      apis: [
        {
          name: 'Push Event API',
          request: {
            method: 'POST',
            path: '/api/v1/push/event',
            headers: 'content-type: multipart/mixed; boundary=plug_conn_test',
            body: {
              user_id: '8589e8ca-6f8b-4917-bf19-4ae5c9f2db8e',
              screen_id: 12,
              project_id: 'b064c40d-c0cf-4c50-b3b3-a1ca41742eb5',
              payload: {
                name: 'ImageWidget',
                id: 'b236ec0b-7450-4af9-8710-52195eed9537',
                data: {
                  src: 'https://cdn-icons-png.flaticon.com/512/197/197374.png',
                  alt: '',
                  actions: {
                    click: {
                      type: 'async_post',
                      url: 'http://localhost:4001/api/set_locale/en',
                    },
                  },
                },
              },
              action: 'insertAfter',
            },
          },
          response: {
            status: 204,
            headers: `cache-control: max-age=0, private, must-revalidate
x-request-id: F9mUi3l9J0WInP0AAAUL`,
            body: '',
          },
        },
        {
          name: 'Push Notification API',
          request: {
            method: 'POST',
            path: '/api/v1/push/notification',
            headers: 'content-type: multipart/mixed; boundary=plug_conn_test',
            body: {
              user_id: '8589e8ca-6f8b-4917-bf19-4ae5c9f2db8e',
              title: 'title',
              message: 'message',
              picture: 'picture',
              large_icon_url: 'large_icon_url',
              big_large_icon_url: 'big_large_icon_url',
            },
          },
          response: {
            status: 202,
            headers: `cache-control: max-age=0, private, must-revalidate
x-request-id: F9mUi3l9J0WInP0AAAUL`,
            body: { message: 'ok' },
          },
        },
      ],
    };
  },
};
</script>

<style>
h1 {
  font-size: 2em;
  margin-bottom: 0.5em;
}
</style>
