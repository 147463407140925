<template>
  <div :if="data" class="listen_events widget row p-5" :style="data.style">
    <div>
      <img class="icon w-100" :src="data.src" :alt="data.src" />
    </div>
    <div class="w-100 pl-5">
      <div class="row space-between w-100 pb-5">
        <div class="title">{{ data.title }}</div>
        <div class="date">{{ data.date }}</div>
      </div>
      <div class="text">
        <div class="text">{{ data.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatPreviewWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    actions: {
      required: false,
      type: Object,
    },
  },
};
</script>
<style scoped>
.icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.text {
  color: #414042;
}

.date {
  color: #414042;
  font-size: 12px;
}
</style>