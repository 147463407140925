import api from '@/utils/axios';

const base_path = '/api/admin/v1/active_users/list';

const activeUsersAPI = {
  async getActiveUsers() {
    return api
      .get(base_path)
      .then((response) => {
        return response.data.list;
      })
      .catch((error) => {
        throw error;
      });
  },
  async getUserEventLog(user_id) {
    return api
      .get(`/api/admin/v1/event_log/${user_id}/list`)
      .then((response) => {
        return response.data.message;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default activeUsersAPI;
