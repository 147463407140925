<template>
  <div class="container">
    <a-divider>Cache</a-divider>

    <div class="flex justify-between items-center mb-4">
      <a-typography-title :level="5">Cache strategy</a-typography-title>
      <div>
        <a-radio-group v-model:value="cacheStrategy" option-type="button" :options="[
          { label: 'Permanent', value: 'permanent' },
          { label: 'Per Session', value: 'session' },
          { label: 'TTL', value: 'ttl' },
          { label: 'None', value: 'none' },
        ]" @change="updateCacheStrategy" />
      </div>
    </div>

    <div v-if="cacheStrategy === 'ttl'" class="flex justify-between items-center">
      <a-typography-title :level="5">TTL Time</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input class="!w-72" v-model:value="ttlTime" placeholder="TTL in ms" />
          <a-button @click="ttlTimeSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>

    <a-divider>Callbacks</a-divider>

    <div class="flex justify-between items-center mb-4">
      <a-typography-title :level="5">On Mount</a-typography-title>
      <div>
        <a-input-group compact class="!flex">
          <a-input class="!w-72" v-model:value="onMountUrl" placeholder="https:my-domain/api/mount" />
          <a-button @click="onMountUrlSubmit" type="primary">Submit</a-button>
        </a-input-group>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, watch } from 'vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'ScreenConfigPage',

  setup() {
    const store = useStore();
    const route = useRoute();

    const screen_id = route?.params?.screen_id;
    const screen = computed(() => store.getters.screens[screen_id]);

    const cacheStrategy = ref('none');
    const ttlTime = ref(null);
    const onMountUrl = ref(null);
    const onUnmountUrl = ref(null);

    // Watch screen and update refs accordingly
    watch(screen, (newScreen) => {
      if (newScreen?.config) {
        cacheStrategy.value = newScreen.config.cache_strategy || 'none';
        ttlTime.value = newScreen.config.ttl_time || null;
        onMountUrl.value = newScreen.config.on_mount_url || null;
        onUnmountUrl.value = newScreen.config.on_unmount_url || null;
      }
    }, { immediate: true });

    const onMountUrlSubmit = async () => {
      const body = {
        body: { config: { ...screen.value.config, on_mount_url: onMountUrl.value } },
      };
      await store.dispatch('updateScreen', { screen_id: screen_id, data: body });
    }

    const onUnmountUrlSubmit = async (_) => {
      const body = {
        body: { config: { ...screen.value.config, on_unmount_url: onUnmountUrl.value } },
      };
      await store.dispatch('updateScreen', { screen_id: screen_id, data: body });
    }

    const ttlTimeSubmit = async (_) => {
      const body = {
        body: { config: { ...screen.value.config, ttl_time: ttlTime.value } },
      };
      await store.dispatch('updateScreen', { screen_id: screen_id, data: body });
    }

    const updateCacheStrategy = async (_) => {
      const body = {
        body: { config: { ...screen.value.config, cache_strategy: cacheStrategy.value, ttl_time: ttlTime.value } },
      };
      await store.dispatch('updateScreen', { screen_id: screen_id, data: body });
    }

    onMounted(() => {
      if (!screen_id) return;
      store.dispatch('getScreen', screen_id);
    });

    return {
      cacheStrategy,
      ttlTime,
      screen,
      onMountUrl,
      onUnmountUrl,
      ttlTimeSubmit,
      onMountUrlSubmit,
      onUnmountUrlSubmit,
      updateCacheStrategy
    };
  },
};
</script>
