<template>
  <a-layout has-sider>
    <a-layout-sider :style="siderStyle" v-model:collapsed="collapsed" :collapsed-width="0" :trigger="null" collapsible>
      <DocSideNav v-if="navMenu === 'docs'" />
      <SideNav v-else />
    </a-layout-sider>
    <a-layout :style="layoutStyle">
      <a-layout-header theme="dark" :style="headerStyle" class="w-full flex justify-between items-center">
        <div class="flex">
          <menu-unfold-outlined v-if="collapsed" class="trigger" @click="toggleCollapse" />
          <menu-fold-outlined v-else class="trigger" @click="toggleCollapse" />
        </div>

        <GlobalDocSearchInput />

        <div class="flex items-center">
          <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="horizontal" :style="menuStyle" class="mr-2">
            <a-menu-item v-for="item in nav_list" :key="item.id">
              <router-link class="nav-text" :to="{ name: item.name }">{{ item.title }}</router-link>
            </a-menu-item>
          </a-menu>

          <a-popover>
            <template #content>
              <a-button @click="handleLogout">Log Out</a-button>
            </template>
            <a-avatar :size="35">
              <template #icon>
                <router-link :to="{ name: 'UserProfile' }">
                  <div v-if="userAvatar">
                    <a-avatar :src="userAvatar" />
                  </div>
                  <UserOutlined v-else />
                </router-link>
              </template>
            </a-avatar>
          </a-popover>
        </div>
      </a-layout-header>
      <a-layout-content :style="contentStyle">
        <router-view />
      </a-layout-content>
      <a-layout-footer :style="footerStyle">
        <div>
          © {{ currentYear }} SDUF. All Rights Reserved.
          <div class="footer-build-info" v-if="buildInfo">
            <span>Build Info:</span>
            <span>{{ buildInfo.version }} ({{ formatDate(buildInfo.date) }})</span>
          </div>
        </div>
        <WhatsNewModal></WhatsNewModal>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import SideNav from './parts/SideNav.vue';
import DocSideNav from './parts/DocSideNav.vue';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons-vue';
import { mapActions, useStore } from 'vuex';
import router from '@/router';
import { ref, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import WhatsNewModal from '../pages/whatsNew/WhatsNewModal.vue';
import GlobalDocSearchInput from './parts/GlobalDocSearchInput.vue';
import buildInfoAPI from '@/api/buildInfo'; // Ensure this path is correct

export default {
  name: 'DefaultLayout',
  components: {
    SideNav,
    DocSideNav,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    WhatsNewModal,
    GlobalDocSearchInput
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const navMenu = ref(null);
    const collapsed = ref(false);
    const buildInfo = ref(null);
    const selectedKeys = ref([]);
    const nav_list = [{ id: 'docs', name: 'Docs', title: 'Docs' }];

    // Get the user's avatar from the Vuex store
    const userAvatar = computed(() => {
      return store.getters.getUserAvatar;
    });

    const currentYear = new Date().getFullYear();

    const setSelectedKey = () => {
      if (route.path.includes('/docs')) {
        selectedKeys.value = ['docs'];
      } else {
        selectedKeys.value = [];
      }
    };

    const setNavMenu = () => {
      navMenu.value = route.meta.nav;
    };

    const toggleCollapse = () => {
      collapsed.value = !collapsed.value;
    };

    watch(route, setSelectedKey, { immediate: true });
    watch(route, setNavMenu, { immediate: true });

    const fetchBuildInfo = async () => {
      try {
        const res = await buildInfoAPI.getBuildInfo();
        buildInfo.value = res; // Assuming res has structure { version: "...", date: "..." }
      } catch (error) {
        console.error('Error fetching build info:', error);
      }
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString(); // Format the date to a more readable format
    };

    // Fetch build info on mount
    fetchBuildInfo();

    return {
      navMenu,
      collapsed,
      buildInfo,
      selectedKeys,
      nav_list,
      userAvatar,
      currentYear,
      toggleCollapse,
      formatDate,
    };
  },
  methods: {
    ...mapActions(['logout', 'userFromSession']),
    handleLogout() {
      this.logout();
      router.push({ name: 'Login' });
    },
  },
  computed: {
    siderStyle() {
      return {
        overflow: 'auto',
        minHeight: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: '#001529',
      };
    },
    layoutStyle() {
      return {
        marginLeft: this.collapsed ? '0' : '200px',
        height: '100vh',
      };
    },
    headerStyle() {
      return {
        padding: '0 24px',
        display: 'flex',
        height: '64px',
        backgroundColor: '#001529',
        color: '#fff',
        position: 'relative',
        zIndex: this.collapsed ? '1000' : 'auto',
      };
    },
    menuStyle() {
      return {
        lineHeight: '64px',
      };
    },
    contentStyle() {
      return {
        overflow: 'scroll',
        overflowX: 'clip',
        padding: '12px',
      };
    },
    footerStyle() {
      return {
        fontSize: '10px',
        textAlign: 'center',
        backgroundColor: '#f0f2f5',
      };
    },
  },
  mounted() {
    this.userFromSession();
  },
};
</script>

<style scoped>
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.ant-layout-footer {
  padding: 10px 50px;
}

.footer-build-info {
  color: #888;
}

.footer-build-info strong {
  color: #555;
}

.nav-text {
  color: #fff;
}

.nav-text:hover {
  color: #1890ff;
}

.a-avatar {
  cursor: pointer;
}

.a-avatar:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .ant-layout-header {
    width: 100%;
    position: fixed;
    z-index: 1000;
  }

  .ant-layout-content {
    padding-top: 70px;
  }

  .ant-layout-footer {
    padding: 10px 20px;
  }
}
</style>
