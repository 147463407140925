<template>
  <div v-if="constructor">TextWidget</div>
  <div v-else class="text" :style="data.style" v-analytics="data.actions">
    {{ data.text }}
  </div>
</template>

<script>
export default {
  name: "TextWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    name: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  }
};
</script>
<style scoped>
.text {
  padding: 10px
}
</style>