<template>
  <HeaderSection />
  <router-view />
  <FooterSection />
</template>

<script>
import FooterSection from './parts/FooterSection.vue';
import HeaderSection from './parts/HeaderSection.vue';

export default {
  components: {
    HeaderSection,
    FooterSection
  },
  name: 'AuthLayout'
}
</script>