<template>
  <textarea class="w-100" :style="data.style" :name="data.name" :placeholder="data.placeholder" :rows="data.rows"
    :cols="data.cols" :disabled="data.disabled" :required="data.required" v-model="text"></textarea>
</template>

<script>
export default {
  name: "TextAreaWidget",
  data() {
    return {
      private_text: null,
    };
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    text: {
      get() {
        if (this.private_text) return this.private_text;
        return this.data.value;
      },
      set(value) {
        this.private_text = value;
      },
    },
  }
};
</script>
<style scoped>
.w-100 {
  width: calc(100% - 25px);
}
</style>