import authAPI from '@/api/auth';
import router from '@/router';
import { useToast } from 'vue-toast-notification';
import store from '@/store';

const $toast = useToast();

export default {
  actions: {
    logout({ commit }) {
      return new Promise((resolve) => {
        commit('logout');
        store.commit('updatedProjectList', []);
        resolve();
      });
    },
    login({ commit }, { email, password }) {
      return new Promise((resolve, reject) => {
        authAPI
          .login(email, password)
          .then((data) => {
            commit('login', data);
            router.push({ name: 'ProjectList' });
            resolve(data.token);
          })
          .catch((error) => {
            $toast.error('Oops... Try again later');
            reject(error);
          });
      });
    },
    register(context, { email, password }) {
      return new Promise((resolve, reject) => {
        authAPI
          .register(email, password)
          .then((response) => {
            router.push({ name: 'Login' });
            resolve(response);
          })
          .catch((error) => {
            $toast.error('Oops... Try again later');
            reject(error);
          });
      });
    },
  },
  mutations: {
    logout(state) {
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-id');
      state.token = '';
      state.userId = null;
    },
    login(state, data) {
      localStorage.setItem('user-token', data.token);
      localStorage.setItem('user-id', data.user_id);
      state.token = data.token;
      state.userId = data.user_id;
    },
  },
  state: {
    token: localStorage.getItem('user-token') || '',
    userId: localStorage.getItem('user-id') || '',
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== '';
    },
    getUserID(state) {
      return state.userId;
    },
  },
};
