<template>
  <div id="app-layout">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
  export default {
    name: 'App',
    mounted() {},
    methods: {},
    computed: {
      layout() {
        return this.$route.meta.layout || 'blank-layout';
      },
    },
  };
</script>
