<template>
  <div class="container">
    <a-divider>Notifications</a-divider>
    <form id="notification-form" @submit.prevent="onSubmit">
      <a-select
        class="mb-3"
        name="projectToken"
        ref="select"
        v-model:value="selectedProject"
        style="width: 100%"
        @change="handleChangeProject"
        placeholder="Select project"
        required
      >
        <a-select-option
          v-for="item in this.projectList"
          :key="item.id"
          :value="item.token"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <a-input
        class="mb-3"
        type="text"
        name="userId"
        v-model:value="userId"
        placeholder="User ID"
      />
      <a-input
        class="mb-3"
        type="text"
        name="title"
        v-model:value="title"
        placeholder="Notification Title"
      />
      <a-input
        class="mb-3"
        type="text"
        name="picture"
        v-model:value="picture"
        placeholder="Picture URL"
      />
      <a-input
        class="mb-3"
        type="text"
        name="large_icon_url"
        v-model:value="largeIconUrl"
        placeholder="Large Icon URL"
      />
      <a-input
        class="mb-3"
        type="text"
        name="big_large_icon_url"
        v-model:value="bigLargeIconUrl"
        placeholder="Big Large Icon URL"
      />
      <a-textarea
        class="mb-3"
        type="text"
        name="message"
        v-model:value="message"
        placeholder="Notification Body"
        :rows="4"
      />
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Send Notification</button>
    </form>
  </div>
</template>

<script>
  import { useToast } from 'vue-toast-notification';
  import { mapActions } from 'vuex';

  const $toast = useToast();

  export default {
    name: 'NotificationsPage',
    data() {
      return {
        userId: '',
        selectedProject: null,
        title: '',
        picture: '',
        largeIconUrl: '',
        bigLargeIconUrl: '',
        message: '',
      };
    },
    methods: {
      ...mapActions(['sendPushNotification', 'getAllProjects']),
      onSubmit() {
        if (this.validateForm()) {
          const allData = this.getAllData();
          this.sendPushNotification(allData);
        }
      },
      validateForm() {
        if (!this.selectedProject) {
          $toast.error('Please select a project');
          return false;
        }
        if (!this.userId) {
          $toast.error('Please enter a User ID');
          return false;
        }
        if (!this.title) {
          $toast.error('Please enter a Notification Title');
          return false;
        }
        if (!this.message) {
          $toast.error('Please enter the Notification Body');
          return false;
        }
        return true;
      },
      getAllData() {
        return {
          projectToken: this.selectedProject,
          userId: this.userId,
          title: this.title,
          picture: this.picture,
          largeIconUrl: this.largeIconUrl,
          bigLargeIconUrl: this.bigLargeIconUrl,
          message: this.message,
        };
      },
      handleChangeProject(value) {
        this.selectedProject = value;
      },
    },
    computed: {
      projectList: {
        get() {
          return this.$store.getters.projectList;
        },
      },
    },
    mounted() {
      this.getAllProjects(this.$store.getters.getUserID);
    },
  };
</script>
