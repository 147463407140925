<template>
  <div class="api-doc">
    <h2>{{ api.name }}</h2>

    <div class="section">
      <h3>Request</h3>
      <ul>
        <li>
          <strong>Method:</strong>
          <select v-model="request.method">
            <option v-for="method in methods" :key="method">
              {{ method }}
            </option>
          </select>
        </li>
        <li>
          <strong>Path:</strong>
          <input type="text" v-model="request.path" />
        </li>
        <li>
          <strong>Request body:</strong>
          <textarea
            v-model="requestBodyString"
            @input="updateRequestBody"
          ></textarea>
        </li>
      </ul>
      <button @click="sendRequest">Send Request</button>
    </div>

    <div class="section" v-if="response">
      <h3>Response</h3>
      <ul>
        <li><strong>Status:</strong> {{ response.status }}</li>
        <li>
          <strong>Response headers:</strong>
          <pre><code>{{ response.headers }}</code></pre>
        </li>
        <li>
          <strong>Response body:</strong>
          <pre><code>{{ formattedResponseBody }}</code></pre>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import axios from '../../../utils/axios';

  export default {
    props: {
      api: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        request: {
          method: this.api.request.method,
          path: this.api.request.path,
          headers: this.api.request.headers,
          body: this.api.request.body,
        },
        response: null,
        requestBodyString: JSON.stringify(this.api.request.body, null, 2),
        methods: ['GET', 'POST', 'PUT', 'DELETE'],
      };
    },
    computed: {
      formattedResponseBody() {
        return this.response ? JSON.stringify(this.response.data, null, 2) : '';
      },
    },
    methods: {
      updateRequestBody(event) {
        try {
          this.request.body = JSON.parse(event.target.value);
        } catch (e) {
          console.error('Invalid JSON', e);
        }
      },
      async sendRequest() {
        try {
          const response = await axios({
            method: this.request.method,
            url: this.request.path,
            data: this.request.body,
          });
          this.response = {
            status: response.status,
            headers: JSON.stringify(response.headers, null, 2),
            data: response.data,
          };
        } catch (error) {
          this.response = {
            status: error.response ? error.response.status : 'Network Error',
            headers: error.response
              ? JSON.stringify(error.response.headers, null, 2)
              : '',
            data: error.response ? error.response.data : error.message,
          };
        }
      },
    },
  };
</script>

<style scoped>
  .api-doc {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }

  h2 {
    font-size: 1.75em;
    margin-top: 0;
  }

  h3 {
    font-size: 1.5em;
    margin-top: 1em;
  }

  .section {
    margin-bottom: 20px;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    margin-bottom: 10px;
  }

  pre {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow-x: auto;
  }

  code {
    font-family: 'Courier New', monospace;
    font-size: 0.9em;
  }

  input[type='text'],
  select,
  textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-family: 'Arial', sans-serif;
  }

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    font-size: 1em;
    cursor: pointer;
  }

  button:hover {
    background-color: #218838;
  }
</style>
