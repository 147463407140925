<template>
  <div>
    <div class="flex pb-2">
      <a-input name="name" type="text" v-model:value="newScreenName" placeholder="New Screen" class="mr-1" />
      <a-button @click="save()" type="primary">Save</a-button>
    </div>
    <a-table :data-source="screens" :columns="columns" :pagination="{ pageSize: 20 }" :scroll="{ y: 600 }">
      <template #customFilterDropdown="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column,
      }">
        <div style="padding: 8px">
          <a-input ref="searchInput" :placeholder="`Search ${column.dataIndex}`" :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block" @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              " @pressEnter="handleSearch(confirm)" />
          <a-button type="primary" size="small" style="width: 90px; margin-right: 8px" @click="handleSearch(confirm)">
            <template #icon>
              <SearchOutlined />
            </template>
            Search
          </a-button>
          <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)">
            Reset
          </a-button>
        </div>
      </template>
      <template #customFilterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'name'">
          <div class="editable-cell">
            <div v-if="editableScreens[record.id]" class="editable-cell-input-wrapper">
              <a-input v-model:value="editableScreens[record.id].name" @pressEnter="update(record)" />
              <check-outlined class="editable-cell-icon-check" @click="update(record)" />
            </div>
            <div v-else class="editable-cell-text-wrapper">
              <router-link v-if="record.name" :to="{ name: 'Constructor', params: { screen_id: record.id } }">
                {{ record.name }}
              </router-link>
              <edit-outlined class="editable-cell-icon" @click="edit(record)" />
            </div>
          </div>
        </template>
        <template v-if="column.key === 'links'">
          <router-link v-if="record.name" :to="{ name: 'ScreenConfig', params: { screen_id: record.id } }">
            Config
          </router-link>
        </template>
        <template v-if="column.key === 'action'">
          <a-button @click="remove(record.id)" class="mr-2">Remove</a-button>
          <a-button @click="clone(record)">Clone</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SearchOutlined } from '@ant-design/icons-vue';
import { CheckOutlined, EditOutlined } from '@ant-design/icons-vue';
import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'ScreenListPage',
  components: {
    SearchOutlined,
    CheckOutlined,
    EditOutlined,
  },
  data() {
    return {
      project_id: this.$route.params.project_id,
      newScreenName: '',
      editableScreens: [],
      columns: [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          customFilterDropdown: true,
          onFilter: (value, record) =>
            record.name
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
        },
        {
          title: 'Links',
          dataIndex: 'links',
          key: 'links',
        },
        {
          title: 'Actions',
          dataIndex: 'action',
          key: 'action',
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      'getAllScreens',
      'createScreen',
      'deleteScreen',
      'updateScreen',
    ]),
    save() {
      let data = {
        name: this.newScreenName,
        body: null,
      };
      this.createScreen({ project_id: this.project_id, data: data });
    },
    remove(screen_id) {
      this.deleteScreen(screen_id);
    },
    clone(screen) {
      let data = {
        name: `${screen.name} copy`,
        body: { config: screen.config, nestedComponents: screen.nestedComponents },
      };
      this.createScreen({ project_id: this.project_id, data: data });
    },
    edit(screen) {
      this.editableScreens[screen.id] = {
        original_name: screen.name,
        ...screen,
      };
    },
    update(screen) {
      const updated = this.editableScreens[screen.id];
      let params = {
        name: updated.name,
        nestedComponents: updated.nestedComponents,
      };

      let body = {
        name: updated.original_name,
        body: params,
      };

      this.updateScreen({ screen_id: screen.id, data: body });
      delete this.editableScreens[screen.id];
    },
    handleSearch(confirm) {
      confirm();
    },
    handleReset(clearFilters) {
      clearFilters({ confirm: true });
    },
  },
  mounted() {
    this.getAllScreens(this.project_id);
  },
  computed: {
    screens: {
      get() {
        return this.$store.getters.screenList;
      },
      set(value) {
        this.$store.commit('updatedScreenList', value);
      },
    },
  },
};
</script>

<style scoped>
.carousel__item {
  min-height: 500px;
  width: 250px;
  background-color: green;
  color: orange;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item__new {
  min-height: 500px;
  width: 250px;
  background-color: blueviolet;
  color: orange;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

.carusel {
  margin: auto;
  max-width: 70%;
}

/* ================================================================= */
.screen-list {
  max-width: 1200px;
  margin: auto;
}

.w-15 {
  width: 15%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.border {
  border: 1px solid black;
}

.row {
  padding: 8px;
}

.editable-cell {
  position: relative;
}

.editable-cell .editable-cell-input-wrapper,
.editable-cell .editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell .editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell .editable-cell-icon,
.editable-cell .editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell .editable-cell-icon {
  margin-top: 4px;
  display: none;
}

.editable-cell .editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell .editable-cell-icon:hover,
.editable-cell .editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
