<template>
    <teleport to="body">
        <div v-if="isOpen" class="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
            @click="onClose">
            <div class="relative bg-white p-4 md:p-6 rounded-lg max-w-full max-h-[90vh] overflow-auto" @click.stop>
                <button class="absolute top-2 right-2 text-2xl md:text-3xl font-bold text-gray-600 hover:text-gray-800"
                    @click="onClose">×</button>
                <img :src="src" :alt="alt" class="w-full h-auto max-h-[80vh] object-contain rounded-lg" />
            </div>
        </div>
    </teleport>
</template>

<script setup>
const props = defineProps({
    isOpen: {
        type: Boolean,
        required: true
    },
    src: {
        type: String,
        required: true
    },
    alt: {
        type: String,
        required: false
    }
});

const emit = defineEmits(['close']);

const onClose = () => {
    emit('close');
};
</script>
