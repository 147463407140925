import pushEventAPI from '@/api/pushEvents';

export default {
  actions: {
    async sendPushEvent(context, data) {
      const response = await pushEventAPI.sendPushEvent(data);
      return response;
    },
  },
  mutations: {},
  state: {},
  getters: {},
};
