<template>
  <div class="label-border" :style="data.style">
    <span>{{ data?.text }}</span>
  </div>
</template>

<script>
export default {
  name: "Label3Widget",
  props: {
    data: {
      required: true,
      type: Object,
    },
  }
};
</script>
<style scoped>
.label-border {
  font-size: 14px;
  border: 2px solid #ff9552;
  border-radius: 20px;
  min-height: 32px;
  padding: 0 12px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  margin-right: 3px;
  margin-bottom: 3px;
}
</style>