<template>
  <div class="row space-between mb-3" :style="data.style">
    <span class="label">{{ data?.text }}</span>
  </div>
</template>

<script>
export default {
  name: "Label2Widget",
  props: {
    data: {
      required: true,
      type: Object,
    }
  }
};
</script>
<style scoped>
.label {
  background: #fff;
  border: 1px solid #256799;
  font-size: 13px;
  line-height: 19px;
  display: inline-block;
  position: relative;
  padding: 0 10px;
  border-radius: 2px;
  margin-right: 10px;
}

</style>