import MiniSearch from "minisearch";

export const apiActions = [
    {
        id: "async-post",
        route: "/docs/be-actions#async-post",
        name: "ASYNC_POST",
        description: "Used to send an asynchronous POST request to the server.",
        params: [
            { name: "url", description: "The endpoint to which the request is sent." },
            { name: "params", description: "The parameters sent in the POST request body." }
        ],
        example: `{
    "type": "async_post",
    "url": "/api/submit",
    "params": {
        "user_id": "123",
        "data": "example"
    }
}`
    },
    {
        id: "async-get",
        route: "/docs/be-actions#async-get",
        name: "ASYNC_GET",
        description: "Used to send an asynchronous GET request to the server.",
        params: [
            { name: "url", description: "The endpoint from which data is fetched." },
            { name: "params", description: "Additional parameters sent in the request." }
        ],
        example: `{
    "type": "async_get",
    "url": "/api/data",
    "params": {
        "query": "search_term"
    }
}`
    },
    {
        id: "sync-post",
        route: "/docs/be-actions#sync-post",
        name: "SYNC_POST",
        description: "Used to send a synchronous POST request to the server.",
        params: [
            { name: "url", description: "The endpoint to which the request is sent." },
            { name: "params", description: "The parameters sent in the POST request body." }
        ],
        example: `{
    "type": "sync_post",
    "url": "/api/update",
    "params": {
        "user_id": "123",
        "update": "new_value"
    }
}`
    },
    {
        id: "sync-get",
        route: "/docs/be-actions#sync-get",
        name: "SYNC_GET",
        description: "Used to send a synchronous GET request to the server.",
        params: [
            { name: "url", description: "The endpoint from which data is fetched." },
            { name: "params", description: "Additional parameters sent in the request." }
        ],
        example: `{
    "type": "sync_get",
    "url": "/api/info",
    "params": {
        "user_id": "123"
    }
}`
    }
];
export const formWidgetActions = [
    {
        id: "pagination",
        name: "PAGINATION",
        route: "/docs/be-actions#pagination",
        description: "Used for handling pagination-related actions in lists or tables.",
        params: [
            { name: "id", description: "The ID of the parent component." },
            { name: "url", description: "The callback URL to handle the pagination request." }
        ],
        example: `{
    "type": "pagination",
    "id": "list123",
    "url": "/api/paginate"
}`
    },
    {
        id: "request-widget",
        route: "/docs/be-actions#request-widget",
        name: "REQUEST_WIDGET",
        description: "Used to request a specific widget, usually for dynamic content or UI elements.",
        params: [
            { name: "id", description: "The ID of the parent component." },
            { name: "url", description: "The callback URL to request the widget." },
            { name: "params", description: "Additional parameters for the widget request." },
            { name: "method", description: "HTTP method for the request, defaults to POST." }
        ],
        example: `{
    "type": "request_widget",
    "id": "widget456",
    "url": "/api/widget",
    "method": "POST",
    "params": {
        "type": "dynamic"
    }
}`
    },
    {
        id: "submit-form",
        route: "/docs/be-actions#submit-form",
        name: "SUBMIT_FORM",
        description: "Used to trigger the submission of a form.",
        params: [
            { name: "form", description: "The form data to be submitted." }
        ],
        example: `{
    "type": "submit_form",
    "form": {
        "name": "John Doe",
        "email": "john.doe@example.com"
    }
}`
    },
    {
        id: "set-state",
        route: "/docs/be-actions#set-state",
        name: "SET_STATE",
        description: "Used to update the state of an element or component, typically to control properties such as loading status, enabled/disabled state, or to trigger a timed reset.",
        params: [
            { name: "form", description: "The form data to be submitted or modified." },
            { name: "loading", description: "Boolean indicating if a loading state should be shown. Set to true to display a loading indicator, false to hide it." },
            { name: "disabled", description: "Boolean to enable or disable the element. Set to true to disable interactions, false to enable them." },
            { name: "reset_timer", description: "Time in milliseconds after which the element or component should reset to its initial state. Optional." }
        ],
        example: `{
    "type": "set_state",
    "loading": true,
    "disabled": true,
    "reset_timer": 2000
}`
    }
];
export const navigationActions = [
    {
        id: "route-to-local",
        route: "/docs/be-actions#route-to-local",
        name: "ROUTE_TO_LOCAL",
        description: "Used for navigating to a local route within the application.",
        params: [
            { name: "screen_name", description: "The name of the screen to navigate to." },
            { name: "params", description: "Additional parameters or query strings for the navigation." }
        ],
        example: `{
    "type": "route_to_local",
    "screen_name": "marketplace",
    "params": {
        "filter": "new_arrivals"
    }
}`
    },
    {
        id: "navigate-to",
        route: "/docs/be-actions#navigate-to",
        name: "NAVIGATE_TO",
        description: "Used to navigate to a specific screen or route within the application.",
        params: [
            { name: "screen_name", description: "The name of the screen to navigate to." },
            { name: "params", description: "Additional parameters or query strings for the navigation." }
        ],
        example: `{
    "type": "navigate_to",
    "screen_name": "user_profile",
    "params": {
        "user_id": "456"
    }
}`
    }
];
export const drawerActions = [
    {
        id: "open-drawer",
        route: "/docs/be-actions#open-drawer",
        name: "OPEN_DRAWER",
        description: "Used to open a navigation drawer in the UI.",
        params: [
            { name: "drawerId", description: "The ID of the drawer to open. (Optional if the drawer ID is handled by the state)" }
        ],
        example: `{
    "type": "open_drawer",
    "drawerId": "main_menu"
}`
    },
    {
        id: "close-drawer",
        route: "/docs/be-actions#close-drawer",
        name: "CLOSE_DRAWER",
        description: "Used to close a navigation drawer in the UI.",
        params: [
            { name: "drawerId", description: "The ID of the drawer to close. (Optional if the drawer ID is handled by the state)" }
        ],
        example: `{
    "type": "close_drawer",
    "drawerId": "main_menu"
}`
    }
];
export const feEvents = [
    {
        id: "on-press",
        route: "/docs/fe-actions#on-press",
        name: "onPress",
        description: "Handles the click event when an element is pressed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "click": {
        "type": "some_action",
        "param1": "value1"
    }
}`
    },
    {
        id: "after-click",
        route: "/docs/fe-actions#after-click",
        name: "afterClick",
        description: "Handles the click event when an element is pressed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "after_click": {
        "type": "some_action",
        "param1": "value1"
    }
}`
    },
    {
        id: "on-change",
        route: "/docs/fe-actions#on-change",
        name: "onChange",
        description: "Handles the change event when a value is updated.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "change": {
        "type": "some_action",
        "some_param": "value"
    }
}`
    },
    {
        id: "on-select-image",
        route: "/docs/fe-actions#on-select-image",
        name: "onSelectImage (for Camera and ImageLibrary Widgets)",
        description: "Handles the event when an image is selected.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "selectImage": {
        "type": "some_action",
        "image_data": "base64_image_string"
    }
}`
    },
    {
        id: "on-long-press",
        route: "/docs/fe-actions#on-long-press",
        name: "onLongPress",
        description: "Handles the long press event when an element is pressed for a longer duration.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "long_press": {
        "type": "some_action",
    }
}`
    },
    {
        id: "on-swiped-right",
        route: "/docs/fe-actions#on-swiped-right",
        name: "onSwipedRight (for SwipeableLayout only)",
        description: "Handles the event when a swipe right action is performed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "swiped_right": {
        "type": "some_action",
    }
}`
    },
    {
        id: "on-swiped-top",
        route: "/docs/fe-actions#on-swiped-top",
        name: "onSwipedTop (for SwipeableLayout only)",
        description: "Handles the event when a swipe top action is performed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "swiped_top": {
        "type": "some_action",
    }
}`
    },
    {
        id: "on-swiped-left",
        route: "/docs/fe-actions#on-swiped-left",
        name: "onSwipedLeft (for SwipeableLayout only)",
        description: "Handles the event when a swipe left action is performed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "swiped_left": {
        "type": "some_action",
    }
}`
    },
    {
        id: "on-swiped-bottom",
        route: "/docs/fe-actions#on-swiped-bottom",
        name: "onSwipedBottom (for SwipeableLayout only)",
        description: "Handles the event when a swipe bottom action is performed.",
        parameters: [
            { name: "type", description: "Name of the action" }
        ],
        example: `{
    "swiped_bottom": {
        "type": "some_action",
    }
}`
    }
];

let miniSearch = new MiniSearch({
    fields: ['id', 'name', 'description', 'params'],
    storeFields: ['id', 'name', 'description', 'params', 'route'],
    searchOptions: {
        fuzzy: 0.3
    }
})

// Index all documents
miniSearch.addAll([
    ...apiActions,
    ...formWidgetActions,
    ...navigationActions,
    ...drawerActions,
    ...feEvents
]);


export { miniSearch };