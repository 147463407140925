<template>
    <select class="w-100" :style="data.style" :name="data.name" :disabled="data.disabled" :required="data.required"
        v-model="selected">
        <option v-for="option in data.options" :key="option.id" :value="option.value">{{ option.text }}</option>
    </select>
</template>

<script>
export default {
    name: "SelectWidget",
    data() {
        return {
            private_selected: null,
        };
    },
    props: {
        data: {
            required: true,
            type: Object,
        }
    },
    computed: {
        selected: {
            get() {
                if (this.private_selected) return this.private_selected;
                return this.data?.selected;
            },
            set(value) {
                this.private_selected = value;
            },
        },
    }
};
</script>