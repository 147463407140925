<template>
  <div>
    <div>
      <router-link class="nav-text" :to="{ name: 'ProjectList' }"
        ><img :src="require('@/assets/main_logo.png')" alt="Logo" class="logo"
      /></router-link>
    </div>
    <a-menu v-model:selectedKeys="selectedKeys" theme="dark" mode="inline">
      <a-menu-item v-for="item in nav_list" :key="item.id">
        <router-link class="nav-text" :to="{ name: item.name }">{{
          item.title
        }}</router-link>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';

  export default {
    name: 'SideNav',
    setup() {
      const selectedKeys = ref([]);
      const nav_list = [
        { id: '1', name: 'ProjectList', title: 'ProjectList' },
        { id: '2', name: 'Notifications', title: 'Notifications' },
        { id: '3', name: 'PushEvent', title: 'PushEvent' },
        { id: '4', name: 'ActiveUsers', title: 'ActiveUsers' },
        { id: '5', name: 'CustomWidgetList', title: 'Custom Widgets' },
        { id: '6', name: 'Examples', title: 'Examples' },
      ];

      const route = useRoute();

      const setSelectedKey = () => {
        const currentRouteName = route.name;
        const matchedNavItem = nav_list.find(
          (item) => item.name === currentRouteName
        );
        if (matchedNavItem) {
          selectedKeys.value = [matchedNavItem.id];
        }
      };

      watch(route, setSelectedKey, { immediate: true });

      return {
        selectedKeys,
        nav_list,
      };
    },
  };
</script>

<style scoped>
  .logo {
    width: 200px;
    height: 84px;
  }
</style>
