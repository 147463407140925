import userAPI from '@/api/user';

export default {
  actions: {
    userFromSession(context) {
      return new Promise((resolve, reject) => {
        userAPI
          .getUserSession()
          .then((response) => {
            context.commit('setUser', response)
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    setUser(state, data) {
      state.avatar = data.avatar;
      state.name = data.name;
      state.userId = data.user_id;
    }
  },
  state: {
    user_id: null,
    name: null,
    avatar: null,
  },
  getters: {
    getUserFromSession(state) {
      return state;
    },
    getUserAvatar(state) {
      return state.avatar;
    }
  },
};
