<template>
  <div class="vue-message">
    <div>
      <router-link class="link" :to="{ name: 'ScreenList' }"
        >Конструктор</router-link
      >
    </div>
    <div>
      <router-link class="link" :to="{ name: 'Examples' }"
        >Приклади</router-link
      >
    </div>
    <div>
      <router-link class="link" :to="{ name: 'Notifications' }"
        >Нотифікації</router-link
      >
    </div>
    <div>
      <router-link class="link" :to="{ name: 'Config' }"
        >Налаштування</router-link
      >
    </div>
    <div>
      <router-link class="link" :to="{ name: 'PushEvent' }"
        >Відправити івент</router-link
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HomePage',
    data() {
      return {};
    },
    methods: {
      sayHello() {
        this.showMessage = true;
      },
    },
  };
</script>
