<template>
  <div>
    <CodeEditor width="100%" :line-nums="true" v-model="localCode"></CodeEditor>
  </div>
</template>

<script>
  /* eslint-disable */
  import hljs from 'highlight.js';
  import CodeEditor from 'simple-code-editor';
  export default {
    props: {
      code: {
        required: true,
      },
    },
    components: {
      CodeEditor,
    },
    data() {
      return {
        localCode: `
// Put your code here
import React, { useState } from 'react'
import { StyleSheet, Text, View } from 'react-native'

export default function TextWidget({ data }) {
    const widgetStyles = data.styles ?? {};

    return (
        <View>
            {data ? <Text style={[styles.text, widgetStyles]}>{data.text}</Text> : null}
        </View>
    );
}

const styles = StyleSheet.create({
    text: {
        paddingBottom: 5
    }
});
      `,
      };
    },
    watch: {
      code(newCode) {
        this.localCode = newCode;
      },
      localCode(newCode) {
        this.$emit('update:code', newCode);
      },
    },
  };
</script>
