<template>
  <div v-if="constructor">Form Widget</div>
  <div v-else>
    <form @submit.prevent="onSubmit" id="frm">
      <component
        v-for="element in nestedComponents"
        :key="element.id"
        :is="element.name"
        :nestedComponents="element.nestedComponents"
        :data="element.data"
        class="bg"
      >
      </component>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "FormWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    nestedComponents: {
      required: true,
      type: Array,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  },
  methods: {
    ...mapActions(["pushScreenEvent"]),
    onSubmit() {
      const allData = this.getAllData();

      let screenName = this.$route.params.screen_name;
      if (this.$route.path === "/client/login") {
        screenName = "login";
      }
      const body = {
        type: "formSubmitted",
        screen: screenName,
        action: this.data.action,
        method: this.data.method,
        params: allData,
      };
      this.pushScreenEvent(body);
    },
    getAllData() {
      let myForm = document.getElementById("frm");
      let formData = new FormData(myForm);
      const data = {};
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val });
      }
      return data;
    },
  },
};
</script>