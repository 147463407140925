<template>
  <div v-if="constructor">ImageCarouselWidget</div>
  <div v-else>
    <Carousel :wrap-around="true" :itemsToScroll="1">
      <Slide v-for="(image, index) in data?.images" :key="image">
        <img class="carousel__item" @click="handleShow(index)" :src="image.src" :alt="image.alt" />
      </Slide>

      <template #addons>
        <Navigation v-if="data.navigation" />
        <Pagination v-if="data.pagination" />
      </template>
    </Carousel>
    <photo-slider :items="items" :visible="visible" :index="index" @changeIndex="changeIndex" @clickMask="handleHide"
      @closeModal="handleHide" />
  </div>

</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import { PhotoSlider } from 'vue3-photo-preview';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'ImageCarouselWidgetBackup',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    PhotoSlider
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {
      index: 0,
      visible: false,
      propsItems: this.data.images,
    };
  },
  computed: {
    items: {
      get() {
        return this.propsItems.map(img => ({
          src: img.src,
          key: img.id,
          intro: img.intro
        }));
      }
    }
  },
  methods: {
    changeIndex(index) {
      this.index = index;
    },

    handleShow(index) {
      if (index > -1) {
        this.index = index;
        this.visible = true;
      }
    },

    handleHide() {
      this.visible = false;
    }
  },
});
</script>

<style>
.carousel__item {
  min-height: 200px;
  max-height: 400px;
  object-fit: cover;
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}
</style>