<template>
    <section :class="[
        'w-full px-4 py-4 md:p-4 h-14 z-10 transition-all duration-300 base-text',
        isSticky ? 'fixed top-0 left-0 bg-black shadow-lg z-30' : 'absolute bg-transparent'
    ]">
        <div class="flex justify-between items-center max-w-screen-xl mx-auto">
            <div class="text-teal-400 text-md md:text-xl font-mono">
                <h1>
                    <router-link to="/" class="cursor-pointer">
                        SDUF<span class="text-indigo-400">._</span>
                    </router-link>
                </h1>
            </div>
            <nav v-if="route.name === 'Landing'" class="text-sm font-mono flex space-x-4 md:space-x-8 hidden lg:flex">
                <a href="#sduf-project" class="cursor-pointer text-gray-400 hover:text-white">
                    // index
                </a>
                <a href="#screen-1" class="cursor-pointer text-gray-400 hover:text-white">
                    // configuration
                </a>
                <a href="#screen-0" class="cursor-pointer text-gray-400 hover:text-white">
                    // ui studio
                </a>
                <a href="#screen-2" class="cursor-pointer text-gray-400 hover:text-white">
                    // result
                </a>
                <router-link :to="{ name: 'Login' }" class="cursor-pointer text-gray-400 hover:text-white">// sign
                    in</router-link>
            </nav>
            <nav v-if="route.name === 'Landing'" class="text-sm font-mono flex space-x-4 md:space-x-8  lg:hidden">
                <router-link :to="{ name: 'Login' }" class="cursor-pointer text-gray-400 hover:text-white">// sign
                    in</router-link>
            </nav>

            <nav v-if="route.name === 'Login'" class="flex text-sm font-mono flex space-x-4 md:space-x-8">
                <router-link :to="{ name: 'Landing' }" class="cursor-pointer text-gray-400 hover:text-white">//
                    main</router-link>
                <router-link :to="{ name: 'Registration' }" class="cursor-pointer text-gray-400 hover:text-white">//
                    sign up</router-link>
            </nav>


            <nav v-if="route.name === 'Registration'" class="flex text-sm font-mono flex space-x-4 md:space-x-8">
                <router-link :to="{ name: 'Landing' }" class="cursor-pointer text-gray-400 hover:text-white">//
                    main</router-link>
                <router-link :to="{ name: 'Login' }" class="cursor-pointer text-gray-400 hover:text-white">// sign
                    in</router-link>
            </nav>
        </div>
    </section>
</template>

<style scoped>
.base-text {
    font-size: 1rem;
    line-height: 1.5;
}
</style>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const isSticky = ref(false);

const handleScroll = () => {
    isSticky.value = window.scrollY > 50;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>
