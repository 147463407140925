<template>
  <div
    class="listen_events widget footer row space-between"
    :style="data.style"
    v-analytics="data.actions"
  >
    <div v-for="item in data.images" :key="item" class="row center">
      <img
        width="30"
        height="30"
        :src="item.src"
        :alt="item.alt"
        v-analytics="item.actions"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    actions: {
      required: false,
      type: Object,
    },
  },
};
</script>
<style scoped>
.footer {
  padding-left: 5%;
  padding-right: 5%;
  min-height: 50px;
  min-width: 200px;
  z-index: 999;
}
</style>