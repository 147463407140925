<template>
  <div class="container">
    <a-divider>Custom Event</a-divider>
    <form id="event-form" class="form" @submit.prevent="onSubmit">
      <a-input class="mb-3" type="text" v-model:value="widgetName" name="name" placeholder="Name" />
      <MonacoEditor v-model:code="widgetContent" language="html" />
      <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Save</button>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import MonacoEditor from '../MonacoEditor.vue';

export default {
  name: 'CustomWidgetPage',
  components: {
    MonacoEditor
  },
  data() {
    return {
      widgetId: this.$route.params.widget_id,
      userId: this.$store.getters.getUserID,
      widgetName: null,
      widgetContent: null,
    };
  },
  methods: {
    ...mapActions(['updateWidget', 'getAllCustomWidgets']),
    onSubmit() {
      const data = {
        widget_id: this.widgetId,
        data: {
          name: this.widgetName,
          content: this.widgetContent,
        },
      };
      this.updateWidget(data);
    },
  },
  mounted() {
    this.getAllCustomWidgets(this.userId);
  },
  computed: {
    widget: {
      get() {
        return this.$store.getters.customWidgetList.find(
          (x) => x.id === this.widgetId
        );
      },
    },
  },
  watch: {
    widget(newWidget) {
      if (newWidget) {
        this.widgetName = newWidget.name;
        this.widgetContent = newWidget.content;
      }
    },
  },
};
</script>

<style scoped>
.form {
  height: 60vh;
}
</style>