<template>
    <section id="sduf-project"
        class="relative flex flex-col items-center justify-center h-screen bg-gray-900 text-white">
        <div class="relative z-20 text-center max-w-4xl mx-auto">
            <h1 class="text-5xl md:text-7xl font-bold">Server Driven UI Framework</h1>
            <p class="mt-4 text-lg md:text-2xl tracking-wide">
                Transform your app development with our intuitive Server Driven UI Framework. Inspired by Airbnb's
                approach, this powerful tool enables non-technical users to easily create and modify app components in
                real-time. Simplify the development process, enhance flexibility, and bring your app ideas to life
                effortlessly!
            </p>
        </div>

        <div class="absolute inset-0 z-1">
            <div class="absolute inset-0 bg-gradient-to-b from-transparent to-[#1A1A1A]"
                :style="backgroundImageStyle" />
        </div>
    </section>
</template>

<script setup>
const imageUrl = require('@/assets/landing/constructor.png');
const backgroundImageStyle = {
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.06
};
</script>
