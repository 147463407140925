import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

const userAPI = {
  async get() {
    return api
      .get('/api/admin/v1/user')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
  async getUserSession() {
    return api
      .get('/api/admin/v1/user/session')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
  async updateInfo(email, name) {
    return api
      .post('/api/admin/v1/user/update', {user: { email, name }})
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updatePassword(password) {
    return api
      .post('/api/admin/v1/user/update_password', {user: { password }})
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default userAPI;
