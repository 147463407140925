<template>
  <section class="bg-black text-white py-4 text-center">
    <div class="container mx-auto">
      <p class="text-sm md:text-base">
        © {{ currentYear }} SDUF. All Rights Reserved.
      </p>
    </div>
  </section>
</template>

<script setup>
// Reactive property for the current year
const currentYear = new Date().getFullYear();
</script>
