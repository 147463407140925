<template>
    <div v-if="constructor">MaplibreWidget</div>
    <div v-else class="map-wrap" :style="data.style">
        <a href="https://www.maptiler.com" class="watermark"><img src="https://api.maptiler.com/resources/logo.svg"
                alt="MapTiler logo" /></a>
        <div class="map" ref="mapContainer"></div>
    </div>
</template>

<script>
import { Map, NavigationControl, Marker } from 'maplibre-gl';
import { shallowRef, onMounted, onUnmounted, markRaw } from 'vue';
import { useStore } from 'vuex'

export default {
    name: "MaplibreWidget",
    props: {
        data: {
            required: true,
            type: Object,
        },
        constructor: {
            required: false,
            type: Boolean,
        },
    },
    setup(props) {
        const store = useStore()
        const mapContainer = shallowRef(null);
        const map = shallowRef(null);
        if (props.constructor) return;

        onMounted(() => {
            const apiKey = 'lkRMy2xGD5DRRllwcbfI';

            let initialState = { lng: props.data.center.lng, lat: props.data.center.lat, zoom: 14 };
            map.value = markRaw(new Map({
                container: mapContainer.value,
                style: `https://api.maptiler.com/maps/streets/style.json?key=${apiKey}`,
                center: [initialState.lng, initialState.lat],
                zoom: initialState.zoom
            }));

            map.value.addControl(new NavigationControl(), 'top-right');

            props.data.markers.forEach(element => {
                let el = { color: "#FF0000" }
                if (element.styleType == 'price') {
                    const width = 50;
                    const height = 15;

                    el = document.createElement('div');
                    el.className = 'marker';
                    el.innerText = element.price;
                    el.style.backgroundColor = '#fff';
                    el.style.padding = '4px';
                    el.style.border = "1px solid #e0e3e4";
                    el.style.borderRadius = "30px";
                    el.style.borderShadow = "0 -4px 8px rgb(0 0 0 / 4%)";
                    el.style.backgroundSize = '100%';
                    el.style.fontSize = '16px';
                    el.style.display = 'flex';
                    el.style.justifyContent = 'center';
                    el.style.alignItems = 'center';
                    el.style.width = `${width}px`;
                    el.style.height = `${height}px`;
                } 

                const marker = new Marker(el)
                    .setLngLat([element.position.lng, element.position.lat])
                    .addTo(map.value);

                marker.getElement().addEventListener('click', () => {
                    map.value.flyTo({
                        center: [element.position.lng, element.position.lat]
                    });
                    store.dispatch("pushScreenEvent", element.onClick)
                });
                marker.getElement().addEventListener('mouseenter', () => {
                    marker.getElement().style.cursor = 'pointer';
                })
            });
            map.value.flyTo({
                center: [props.data.markers[0].position.lng, props.data.markers[0].position.lat]
            });

        }),
            onUnmounted(() => {
                map.value?.remove();
            })

        return {
            map, mapContainer
        };
    }
};
</script>


<style scoped>
@import '~maplibre-gl/dist/maplibre-gl.css';

.map-wrap {
    position: relative;
    width: 100%;
    height: calc(100vh - 77px);
    /* calculate height of the screen minus the heading */
}

.map {
    position: absolute;
    width: 100%;
    height: 100%;
}

.watermark {
    display: none;
}
</style>