<template>
    <div :if="data" id="search" class="listen_events widget row space-between header" :style="data.style">
        <input type="text" placeholder="Search..." />
        <button @click="search">Search</button>
        <button @click="clearSearch">Clear</button>
    </div>
</template>
  
<script>
export default {
    name: "SearchWidget",
    props: {
        data: {
            required: true,
            type: Object,
        },
    },
    methods: {
    },
};
</script>
<style scoped>
.header {
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    min-height: 45px;
    min-width: 200px;
    z-index: 999;
}
</style>