import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

const authAPI = {
  async login(email, password) {
    return api
      .post('/api/admin/v1/user/login', { email, password })
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
  async register(email, password) {
    return api
      .post('/api/admin/v1/user/register', { email, password })
      .then((response) => {
        $toast.success('Registered');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default authAPI;
