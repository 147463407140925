import pushNotificationAPI from '@/api/pushNotifications';

export default {
  actions: {
    async sendPushNotification(context, data) {
      const response = await pushNotificationAPI.sendPushNotification(data);
      return response;
    },
  },
  mutations: {},
  state: {},
  getters: {},
};
