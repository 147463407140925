import actionAPI from '@/api/actions';

export default {
  actions: {
    async getActionList({ commit, state }) {
      if (state.list.length > 0) return;

      const data = await actionAPI.getActionList();
      commit('updateList', data.list);
    },
  },

  mutations: {
    updateList(state, data) {
      state.list = data;
    },
  },

  state: {
    list: {},
  },

  getters: {
    actionList(state) {
      return state.list;
    },
  },
};
