import api from '@/utils/axios';

const buildInfoAPI = {
  async getBuildInfo() {
    try {
      const response = await api
        .get('/build-info');
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default buildInfoAPI;
