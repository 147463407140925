<template>
  <div class="bg">
    <span v-if="memo"> Memo: {{ memo }} </span>
    <component
      v-if="isComponentAvailable"
      :is="componentName"
      :key="id"
      :id="id"
      :nestedComponents="nestedComponents"
      :data="data"
      :name="componentName"
      :actions="actions"
      :styles="styles"
      :constructor="true"
    >
    </component>
    <div v-else>{{ componentDisplayName || componentName }}</div>
  </div>
</template>

<script>
  import { getCurrentInstance, defineComponent, computed } from 'vue';

  function isComponentAvailable(componentName) {
    const instance = getCurrentInstance();
    return !!instance?.appContext.components[componentName];
  }

  export default defineComponent({
    props: {
      componentName: {
        type: String,
        required: true,
      },
      componentDisplayName: String,
      data: Object,
      nestedComponents: Array,
      actions: Array,
      styles: Array,
      memo: String,
      id: String,
    },
    setup(props) {
      const isComponentAvailableComputed = computed(() => {
        return isComponentAvailable(props.componentName);
      });

      return {
        isComponentAvailable: isComponentAvailableComputed,
      };
    },
  });
</script>
