import customWidgetAPI from '@/api/customWidgets';

export default {
  actions: {
    async getAllCustomWidgets({ commit }, userId) {
      try {
        const widgetList = await customWidgetAPI.getAllCustomWidgets(userId);
        commit('updatedCustomWidgetList', widgetList);
      } catch (error) {
        // Handle error if needed
      }
    },
    async createCustomWidget({ commit }, data) {
      try {
        const widget = await customWidgetAPI.createCustomWidget(data);
        commit('addToCustomWidgetList', widget);
      } catch (error) {
        // Handle error if needed
      }
    },
    async updateWidget(context, { widget_id, data }) {
      try {
        await customWidgetAPI.updateWidget(widget_id, data);
        // Optionally commit a mutation if you need to update the state
      } catch (error) {
        // Handle error if needed
      }
    },
    async deleteProject(context, widget_id) {
      try {
        await customWidgetAPI.deleteProject(widget_id);
        // Optionally commit a mutation if you need to update the state
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updatedCustomWidgetList(state, widgetList) {
      state.widgetList = widgetList;
    },
    addToCustomWidgetList(state, widget) {
      state.widgetList.push(widget);
    },
  },
  state: {
    widgetList: [],
  },
  getters: {
    customWidgetList(state) {
      return state.widgetList;
    },
  },
};
