<template>
  <div>
    <a-divider>Logs</a-divider>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: 'LogsPage',
    data() {
      return {};
    },
    methods: {
      ...mapActions(['sendPushEvent']),
      onSubmit() {
        const allData = this.getAllData();

        if (this.isValidJSON(allData.widget)) {
          this.sendPushEvent(allData);
        }
      },
      getAllData() {
        let myForm = document.getElementById('event-form');
        let formData = new FormData(myForm);
        const data = {};
        for (let [key, val] of formData.entries()) {
          Object.assign(data, { [key]: val });
        }
        return data;
      },
      isValidJSON(text) {
        try {
          JSON.parse(text);
          return true;
        } catch (e) {
          return false;
        }
      },
    },
    computed: {},
  };
</script>

<style scoped></style>
