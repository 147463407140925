<template>
  <div class="list" :style="data.style" v-analytics="data.actions">
    <ul>
      <li v-for="item in data.list" :key="item">{{item}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ListWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
    name: {
      required: false,
      type: String,
    },
    id: {
      required: false,
      type: String,
    },
    constructor: {
      required: false,
      type: Boolean,
    },
  }
};
</script>
<style scoped>
.list {
  padding: 10px
}
</style>