import activeUsersAPI from '@/api/activeUsers';

export default {
  actions: {
    async getActiveUsers({ commit }) {
      try {
        const resultList = await activeUsersAPI.getActiveUsers();
        commit('updateActiveUsersList', resultList);
      } catch (error) {
        // Handle error if needed
      }
    },
  },
  mutations: {
    updateActiveUsersList(state, data) {
      state.list = data;
    },
  },
  state: {
    list: [],
  },
  getters: {
    activeUsers(state) {
      return state.list;
    },
  },
};
