<template>
    <button class="listen_events w-100" :style="data.style" v-analytics="data.actions" :type="data.type" :disabled="data.disabled" :name="data.name" :value="data.value">{{ data.text }}</button>
</template>

<script>
export default {
    name: "ButtonWidget",
    props: {
        data: {
            required: true,
            type: Object,
        },
        constructor: {
            required: false,
            type: Boolean,
        }
    }
};
</script>