<template>
  <div class="content-wrapper">
    <h1 class="title">Getting Started</h1>

    <!-- Section 1: How to Create a Project -->
    <section class="section">
      <h2 class="section-title">How to Create a Project</h2>
      <p class="section-description">
        In this section, you'll learn how to create a new project from scratch. We'll walk you
        through the necessary steps, from setting up your development environment to creating your
        first project. Watch the video below for a detailed guide.
      </p>
      <div class="video-container">
        <iframe :src="projectCreationVideoSrc" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>

    <!-- Section 2: How to Create a Build -->
    <section class="section">
      <h2 class="section-title">How to Create a Build</h2>
      <p class="section-description">
        Once your project is ready, you'll need to create a build for deployment. This video covers
        the build process, including configuring your build scripts and generating the final output
        for production.
      </p>
      <div class="video-container">
        <iframe :src="buildCreationVideoSrc" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>

    <!-- Section 3: How to Set Up a Screen -->
    <section class="section">
      <h2 class="section-title">How to Set Up a Screen</h2>
      <p class="section-description">
        Setting up screens is crucial for creating a user-friendly interface. In this tutorial,
        you'll learn how to set up different screens in your project, ensuring a smooth user
        experience. The video will guide you through the setup process.
      </p>
      <div class="video-container">
        <iframe :src="screenSetupVideoSrc" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>

    <!-- Section 4: How to Create a Marketplace Screen -->
    <section class="section">
      <h2 class="section-title">How to Create a Marketplace Screen</h2>
      <p class="section-description">
        The marketplace screen is a key feature in many applications. This video will show you how
        to design and implement a marketplace screen, complete with product listings, filters, and
        other essential components. In the video, we use an example API to demonstrate the process.
        You can check out the example API used in the video
        <a href="http://localhost:4000/docs/demo-api#sdufweb-admin-democontroller-marketplace" target="_blank">
          here
        </a>.
      </p>
      <div class="video-container">
        <iframe :src="marketplaceScreenVideoSrc" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  data() {
    return {
      projectCreationVideoSrc: 'https://drive.google.com/file/d/1oKx_HyH2Srr_VcqFz5Fr2xv83_yq2bbi/preview',
      buildCreationVideoSrc: 'https://drive.google.com/file/d/1Pw2SxA3Czbyp91TPTSNp3gdY7OwodPh1/preview',
      screenSetupVideoSrc: 'https://drive.google.com/file/d/17wf2I166KrgC9jMwHKpuxrDsTnWQlc7x/preview',
      marketplaceScreenVideoSrc: 'https://drive.google.com/file/d/1KadHMCapoyYyACJ_VhxgAesuSao6yDnP/preview'
    };
  }
};
</script>


<style scoped>
/* Main container for the content */
.content-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  border-radius: 8px;
}

/* Main title */
.title {
  font-size: 32px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

/* Section styling */
.section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Section title */
.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #34495e;
  margin-bottom: 15px;
}

/* Section description */
.section-description {
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Video container styling */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
</style>
