<template>
    <div>
      <div class="row justify-center">
        <a-button type="primary" @click="save()">Save</a-button>
        <a-button type="primary" @click="exportToFile()">Export</a-button>
        <a-button type="primary" @click="triggerFileInput()">Import</a-button>
        <a-button type="primary" @click="saveAndBroadcast()">Save and broadcast</a-button>
      </div>
      <div class="constructor">
        <section>
          <a-input-search v-model:value="searchComponentName" placeholder="input name" @change="onSearch" />
          <div class="component-list scrollable mb-3">
            <ComponentList :list="componentList" />
          </div>
        </section>
        <section class="nested-constructor scrollable">
          <NestedConstructor class="dropzone" :list="onPageComponents" />
        </section>
        <section :class="{ 'constructor-config-menu': true, 'expanded': isConfigMenuExpanded }">
          <div class="constructor">
            <div>Expand <a-switch v-model:checked="isConfigMenuExpanded" /></div>
            <a href="/docs/be-actions" target="_blank" class="doc-link">
              Action Documentation
            </a>
            <div>
              Swith View <a-switch v-model:checked="isNewEditor" />
  
            </div>
          </div>
          <ConstructorConfigMenu :isNewEditor="isNewEditor" />
        </section>
      </div>
      <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" accept=".json" />
    </div>
  </template>
  
  <script>
  import { mapActions } from 'vuex';
  import ComponentList from './../constructor/ComponentList.vue';
  import NestedConstructor from '../constructor/helpers/NestedConstructor.vue';
  import ConstructorConfigMenu from './../constructor/ConstructorConfigMenu.vue';
  import 'vue-toast-notification/dist/theme-sugar.css';
  
  export default {
    name: 'ConstructorPageOld',
    components: {
      ComponentList,
      NestedConstructor,
      ConstructorConfigMenu,
    },
    data() {
      return {
        screen_id: this.$route.params.screen_id,
        screen: null,
        searchComponentName: null,
        componentList: this.$store.getters.allComponents,
        isConfigMenuExpanded: false,
        isNewEditor: false,
      };
    },
    mounted() {
      this.init();
    },
    watch: {
      $route: 'init',
    },
    methods: {
      ...mapActions([
        'getScreenByID',
        'updateScreen',
        'updateAndBroadcastScreen',
        'getScreen',
      ]),
  
      saveAndBroadcast() {
        const screen = this.$store.getters.screens[this.screen_id];
  
        let params = {
          nestedComponents: this.onPageComponents,
        };
        let body = {
          name: screen.name,
          body: params,
        };
        this.updateAndBroadcastScreen({
          screen_id: this.screen_id,
          data: body,
        });
      },
      save() {
        const screen = this.$store.getters.screens[this.screen_id];
  
        let params = {
          nestedComponents: this.onPageComponents,
        };
        let body = {
          name: screen.name,
          body: params,
        };
        this.updateScreen({ screen_id: this.screen_id, data: body });
      },
      onSearch(e) {
        this.componentList = this.$store.getters.allComponents.filter((x) =>
          x.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
      },
      init() {
        if (!this.screen_id) return;
        this.getScreen(this.screen_id);
      },
      exportToFile() {
        const jsonData = JSON.stringify(this.onPageComponents, null, 2);
        // Creating a downloadable JSON file
        const blob = new Blob([jsonData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.$route.params.screen_id}.json`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      handleFileUpload(event) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const jsonData = JSON.parse(e.target.result);
              this.onPageComponents = jsonData; // Update your state with the imported data
              // Optionally, save the imported data to the store or process it further
            } catch (error) {
              console.error('Error parsing JSON:', error);
            }
          };
          reader.readAsText(file);
        }
      },
      import() {
        this.triggerFileInput();
      },
      toggleConfigMenuWidth() {
        this.isConfigMenuExpanded = !this.isConfigMenuExpanded;
      },
    },
    computed: {
      allComponents: {
        get() {
          return this.$store.getters.allComponents;
        },
        set(value) {
          this.$store.commit('updateComponents', value);
        },
      },
      onPageComponents: {
        get() {
          return this.$store.getters.onPageComponents;
        },
        set(value) {
          this.$store.commit('updateOnPageComponents', value);
        },
      },
    },
  };
  </script>
  
  <style scoped>
  .constructor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .component-list {
    width: 300px;
    max-height: 75vh;
  }
  
  .nested-constructor {
    width: 400px;
    max-height: 80vh;
  }
  
  :deep(.jsoneditor-vue) {
    height: 75vh;
  }
  
  .constructor-config-menu {
    width: 400px;
  }
  
  .constructor-config-menu.expanded {
    width: 800px;
  }
  
  .dropzone {
    min-height: 80vh;
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .save-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>
  