import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();
const base_path = '/api/admin/v1/configs';

const configAPI = {
  async requireLogin(data) {
    return api
      .post(`${base_path}/require_login`, data)
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async enableMaintenance(data) {
    return api
      .post(`${base_path}/enable_maintenance`, data)
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async getConfigs(project_id) {
    return api
      .get(`${base_path}/${project_id}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
  async updateConfig(data) {
    return api
      .post(`${base_path}/update`, data)
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateSystemEnv(data) {
    return api
      .post(`${base_path}/system_env/update`, data)
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateUserEnv(data) {
    return api
      .post(`${base_path}/user_env/update`, data)
      .then((response) => {
        $toast.success('Updated');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async removeUserEnv(data) {
    return api
      .post(`${base_path}/user_env/remove`, data)
      .then((response) => {
        $toast.success('Removed');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default configAPI;
