<template>
  <div
    :if="data"
    class="listen_events widget row space-between"
    v-analytics="data.actions"
    :style="data.style"
  >
    <img class="w-20px" :src="data.src" :alt="data.alt" />
  </div>
</template>

<script>
export default {
  name: "IconWidget",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
}
</style>