<template></template>

<script setup>
import router from '@/router';
import store from '@/store';
import { useRoute } from 'vue-router';

const route = useRoute();

store.commit('login', {token: route.params.token, user_id: route.params.user_id});
router.replace({name: "ProjectList"})
</script>