<template>
  <div class="wrapper">
    <div class="listen_events widget content p-5">
      <div class="name">{{ data.name }}</div>
      <div class="message">{{ data.text }}</div>
      <div>
        <LigthBoxWidget :data="data" />
      </div>
      <div class="date">{{ data.date }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatMessageWidgetBackup",
  props: {
    data: {
      required: true,
      type: Object,
    },
  },
};
</script>
<style scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.content {
  min-width: 56px;
  max-width: 100%;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0 rgb(16 35 47 / 15%);
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  background-color: #eeffde;

  margin-bottom: 15px;
  max-width: 70%;
}

.message {
  max-width: 480px;
  font-size: 16px;
  font-size: 16px;
  padding: 0 0.5rem 0.375rem 0.625rem;
  max-width: 100%;
  color: #000;
  color: #000;
  line-height: 1.3125;
  word-break: break-word;
  white-space: pre-wrap;
  position: relative;
}

.name {
  padding: 0.3125rem 0.625rem 0 0.625rem;
  font-weight: 500 !important;
  color: #3390ec;
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-avatar {
  position: absolute;
  margin-left: -45px;
  bottom: 0;
  cursor: pointer;
}

.avatar-40 {
  --size: 40px;
  --multiplier: 1.35;
}

.date {
  pointer-events: all;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: inherit;
  white-space: nowrap;
  height: 12px;
  visibility: visible;
}
</style>