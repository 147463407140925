<template>
  <div class="listen_events fff-bg" :style="data.style" v-analytics="data.actions">
    <div class="item-wrapper">
      <section>
        <div class="title">{{ data.title }}</div>
      </section>
      <section>
        <div class="sub_title">{{ data.sub_title }}</div>
      </section>
      <section v-if="data.price">
        <div v-if="data.price.uah">
          <span>{{ data.price.uah }}</span>
          <span> грн</span>
          <span> • </span>
        </div>
        <div v-if="data.price.usd">
          <span class="usd">{{ data.price.usd }}</span>
          <span class="usd"> $</span>
        </div>
        <div v-if="data.price.text">
          <span class="usd">{{ data.price.text }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Item2Header",
  props: {
    data: {
      required: true,
      type: Object,
    },
    actions: {
      required: false,
      type: Object,
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.column-count {
  column-count: 2;
}

.item-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}

.title {
  font-weight: bold;
}

.sub_title {
  color: #414042;
}

.date {
  font-size: 14px;
  color: #9b9b9b;
}

.usd {
  color: #3c9806;
  font-size: 22;
  font-weight: 700;
}

.point-before {
  margin-left: 10px;
  padding-left: 10px;
}

.point-before:before {
  background: #000;
  padding: 1px;
  border-radius: 50%;
  display: inline-block;
  left: -10px;
  position: relative;
  top: -4px;
  content: "";
}

.params .point-before:first-child {
  margin: 0;
  padding: 0;
}

.point-before:first-child:before {
  display: none;
}

section {
  padding-bottom: 5px;
}
</style>