<template>
    <div id="screens">
        <section
            v-for="(screen, index) in screens"
            :key="index"
            :id="`screen-${index}`"
            class="relative flex flex-col items-center justify-center min-h-screen bg-black text-white"
            :style="{ backgroundColor: '#100D0E' }"
        >
            <div class="relative z-20 flex flex-col items-center px-6 md:px-12 lg:px-24 py-16 md:py-24 text-center max-w-4xl mx-auto">
                <div v-if="screen.images" class="flex flex-col md:flex-row gap-8 md:gap-12 mb-8">
                    <div
                        v-for="(image, imgIndex) in screen.images"
                        :key="imgIndex"
                        class="flex-1 cursor-pointer"
                        @click="openModal(image.src, image.alt)"
                    >
                        <img
                            :src="image.src"
                            :alt="image.alt"
                            class="w-full h-auto max-w-xs md:max-w-md lg:max-w-lg rounded-lg shadow-2xl"
                        />
                        <h3 class="text-xl md:text-2xl font-semibold mt-4">{{ image.title }}</h3>
                    </div>
                </div>
                <img
                    v-else
                    :src="screen.src"
                    :alt="screen.alt"
                    class="max-h-96 max-w-xs md:max-w-lg lg:max-w-3xl h-auto rounded-lg shadow-2xl mb-6 cursor-pointer"
                    @click="openModal(screen.src, screen.alt)"
                />
                <h2 class="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4">{{ screen.title }}</h2>
                <p class="text-base md:text-lg lg:text-xl leading-relaxed text-gray-300">
                    {{ screen.description }}
                </p>
            </div>
        </section>
        <Modal v-if="modalImage" :isOpen="modalOpen" @close="closeModal" :src="modalImage?.src" :alt="modalImage?.alt" />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import Modal from './Modal.vue';

const modalImage = ref(null);
const modalOpen = ref(false);

const openModal = (src, alt) => {
    modalImage.value = { src, alt };
    modalOpen.value = true;
};

const closeModal = () => {
    modalOpen.value = false;
    modalImage.value = null;
};

const screens = [
    {
        src: require('@/assets/landing/constructor.png'),
        alt: 'Dynamic UI Configuration',
        title: 'Dynamic User Interface Customization',
        description: 'Experience our server-driven UI capabilities that allow users to dynamically modify layouts and content via an intuitive web panel. This flexibility empowers teams to adapt quickly to changing requirements and enhances user engagement.',
    },
    {
        images: [
            { src: require('@/assets/landing/generate_build.png'), alt: 'Trigger Build', title: 'Instant Build Activation' },
            { src: require('@/assets/landing/logs.png'), alt: 'Build Progress', title: 'Real-Time Build Monitoring' },
        ],
        alt: 'Configuration Management',
        title: 'Comprehensive Build Management',
        description: 'Our Configuration Management interface provides a comprehensive solution for managing build processes. Effortlessly trigger new builds, adjust settings, and monitor progress with real-time updates. Access detailed logs to streamline your development workflow and enhance efficiency across your teams.',
    },
    {
        src: require('@/assets/landing/mobile.jpg'),
        alt: 'Mobile Result',
        title: 'Seamless Mobile Experience',
        description: 'View the polished result of your application on an actual mobile device. This section illustrates the user-friendly interface and seamless functionality that end-users can expect, reinforcing our commitment to quality and performance.',
    },
];
</script>
