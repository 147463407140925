<template>
  <div class="pb-3 label" :style="data.style">
    <img class="img" :src="data?.src" :alt="data?.alt" />
    <span class="text">{{ data?.text }}</span>
  </div>
</template>

<script>
export default {
  name: "Label1Widget",
  props: {
    data: {
      required: true,
      type: Object,
    },
  }
};
</script>
<style scoped>
.img {
  width: 16px;
  height: 16px;
}
.text {
  padding-left: 5%;
}
.label {
  display: flex;
  align-items: center;
}
</style>
