import api from '@/utils/axios';

const actionAPI = {
  getActionList() {
    return api
      .get('/api/admin/v1/action/list')
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default actionAPI;
