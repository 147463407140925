import api from '@/utils/axios';
import { useToast } from 'vue-toast-notification';

const $toast = useToast();

const customWidgetAPI = {
  async getAllCustomWidgets(userId) {
    return api
      .get(`/api/admin/v1/custom_widget_list/${userId}`)
      .then((response) => response.data)
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async createCustomWidget(data) {
    return api
      .post('/api/admin/v1/custom_widget', { widget: data })
      .then((response) => {
        $toast.success('Widget Created');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
  async updateWidget(widget_id, data) {
    const response = await api.put(`/api/admin/v1/custom_widget/${widget_id}`, {
      widget: data,
    });
    $toast.success('Widget Updated');
    return response.data;
  },
  async deleteProject(widget_id) {
    return api
      .delete(`/api/admin/v1/custom_widget/${widget_id}`)
      .then((response) => {
        $toast.success('Widget Deleted');
        return response.data;
      })
      .catch((error) => {
        $toast.error('Opps... Try again');
        throw error;
      });
  },
};

export default customWidgetAPI;
